import React from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { StyledButton } from "../components/SmallComponents/AppComponents";
import ftrbg from "../Images/ftrbg.png";
import Footer from "./Footer";

export default function Newsletter() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <>
      <Box pt={5}>
        <Container maxWidth="md">
          <Typography
            sx={{
              fontSize: { xs: "28px", md: "32px" },
              fontWeight: "800",
              fontFamily: "Raleway",
              color: "#fff",
              textAlign: "center",
            }}
          >
            We're Here to Help
          </Typography>
          <Typography
            mt={2}
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              fontFamily: "Inter",
              color: "#D9D9D9",
              textAlign: "center",
            }}
          >
            The Lion capital team is always here to help if you have any
            question, feedback, concerns or would just like to say hello, please
            click the button below.
          </Typography>
          <Box display="flex" justifyContent="center" mt={4}>
            <StyledButton width="180px">Contact Us</StyledButton>
          </Box>

          {/* <Box pt={matches ? 10 : 57} pb={matches ? 0 : 20}> */}
          {/* <Box
              sx={{
                width: "100%",
                height: matches ? "100%" : "157px",
                background: "#161718",
                border: "1px solid",
                borderImage:
                  "linear-gradient(90.11deg, #EAB10B 0.07%, rgba(234, 177, 11, 0) 40%, rgba(243, 141, 62, 0) 66.28%, #F38D3E 101.16%)",
                borderImageSlice: "1",
              }}
            >
              <Grid container spacing={5} display="flex" alignItems="center">
                <Grid item xs={12} md={6}>
                  <Typography
                    px={matches ? 2 : 4}
                    pt={matches ? 2 : 4}
                    sx={{
                      fontSize: "18px",
                      fontWeight: "500",
                      fontFamily: "Inter",
                      color: "#fff",
                    }}
                  >
                    Sign up to our newsletter.
                  </Typography>
                  <Typography
                    pt={1}
                    px={matches ? 2 : 4}
                    pb={matches ? 2 : 4}
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "Inter",
                      color: "#D9D9D9",
                    }}
                  >
                    Stay in the loop within our TradiFi & DeFi News, Lion
                    Capital Events, Researches and more
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Box
                    m={matches ? 2 : 4}
                    sx={{
                      background: "transparent",
                      width: "100%",
                      border: "1px solid #EAB10B",
                      borderRadius: "40px",
                      height: "55px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      p: 1,
                    }}
                  >
                    <input
                      type="email"
                      style={{
                        height: "40px",
                        width: "80%",
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "left",
                        color: "#fff",
                        fontFamily: "Inter",
                        backgroundColor: "transparent",
                        paddingLeft: "15px",
                        border: "none",
                        outline: "none",
                      }}
                      placeholder="example@gmail.com"
                    />
                    <StyledButton width="135px">Signup</StyledButton>
                  </Box>
                </Grid>
              </Grid>
            </Box> */}
          {/* </Box> */}
        </Container>
        <Box
          pt={matches ? 0 : 40}
          sx={{
            backgroundImage: `url(${ftrbg})`,
            backgroundSize: matches ? "0% 0%" : "100% 100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: "transparent",
            height: "100%",
          }}
        >
          <Footer />
        </Box>
      </Box>
    </>
  );
}
