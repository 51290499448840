import {
  Box,
  Button,
  Container,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  StyledInput,
  ToastNotify,
  TyposBox,
} from "../../../components/SmallComponents/AppComponents";
import { AppContext } from "../../../utils";
import { formatUnits } from "viem";
import {
  stakeReadFunction,
  stakeWriteFunction,
} from "../../../ConnectivityAssets/hooks";
import Loading from "../../../components/SmallComponents/loading";
import { PulseLoader } from "react-spinners";
import { Navgate, useNavigate } from "react-router-dom";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { CopyToClipboard } from "react-copy-to-clipboard";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    padding: "16px",
  },
}));

const packsInfo = [
  {
    rank: "2nd",
    name: "Alpha ",
    count: "123",
    amount: "$289",
  },
  {
    rank: "2nd",
    name: "Alpha ",
    count: "123",
    amount: "$289",
  },
  {
    rank: "2nd",
    name: "Alpha ",
    count: "123",
    amount: "$289",
  },
];
const postsPerPage = 8;

export default function Packs() {
  const matches = useMediaQuery("(max-width:950px)");
  const { account } = useContext(AppContext);
  const navigate = useNavigate();
  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const [currentPage, setCurrentPage] = useState(1);
  const handleChangepage = (event, value) => {
    setCurrentPage(value);
  };

  const [loading, setLoading] = useState(false);
  const [exist, setExist] = useState(false);
  const [teamInfo, setTeamInfo] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [userInfo, setUserInfo] = useState({
    teamName: "",
    members: 0,
    teamAmount: 0,
    noOfRefer: 0,
    referReward: 0,
    lead: "",
  });
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };

  const fetchData = useCallback(async () => {
    try {
      const teams = await stakeReadFunction("uniqueTeamId");

      let data1 = [];

      if (account) {
        const value = await stakeReadFunction("getUserInfo", [account]);
        const _isExists = value[0];
        setExist(_isExists);
      }

      for (let i = 1; i <= Number(teams.toString()); i++) {
        const userTeam = await stakeReadFunction("getTeamInfo", [i]);
        const _teamAmount = +formatUnits(userTeam[3].toString(), 18);
        const _teamCount = userTeam[2].toString();
        const _teamName = userTeam[1];
        const _currentPercent = userTeam[4].toString();
        const _Lead = userTeam[0].toString();

        const obj = {
          lead: _Lead,
          percenet: Number(_currentPercent),
          amount: _teamAmount,
          count: Number(_teamCount),
          name: _teamName,
        };
        data1.push(obj);
      }
      data1.sort((a, b) => {
        return b.amount - a.amount;
      });
      data1 = data1.map((obj, i) => {
        return { ...obj, rank: i };
      });
      setTeamInfo([...data1]);
    } catch (error) {
      console.error(error);
      setLoading(false);
      const errorMessage = error?.shortMessage;
      showAlert(errorMessage);
    }
  }, [account]);

  const fetchUserInfo = useCallback(async () => {
    try {
      const value = await stakeReadFunction("getUserInfo", [account]);
      const _referrals = value[3].toString();
      const _referralRewards = +formatUnits(value[4].toString(), 18);
      const _teamId = value[5].toString();

      const userTeam = await stakeReadFunction("getTeamInfo", [_teamId]);
      const _teamAmount = +formatUnits(userTeam[3].toString(), 18);
      const _teamCount = userTeam[2].toString();
      const _teamName = userTeam[1];
      const _Lead = userTeam[0].toString();

      setUserInfo({
        teamName: _teamName,
        members: _teamCount,
        teamAmount: _teamAmount,
        noOfRefer: _referrals,
        referReward: _referralRewards,
        lead: _Lead,
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      const errorMessage = error?.shortMessage;
      showAlert(errorMessage);
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      fetchData();
      fetchUserInfo();
    }
  }, [account, fetchData, fetchUserInfo]);

  const changeTeamName = useCallback(async () => {
    try {
      if (teamName.length === 0) {
        return showAlert("Enter Name");
      }
      if (
        teamName.includes("www") ||
        teamName.includes(".com") ||
        teamName.includes("https")
      ) {
        return showAlert("Invalid text or URL. Please try again.");
      }
      setLoading(true);
      await stakeWriteFunction("setTeamName", [teamName]);
      await fetchUserInfo();
      await fetchData();

      setAlertState({
        open: true,
        message: `Lion pride name changed successfully.`,
        severity: "success",
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      const errorMessage = error?.shortMessage;
      showAlert(errorMessage);
    }
  }, [fetchData, fetchUserInfo, teamName]);

  const pageCount = Math.ceil(teamInfo.length / postsPerPage);
  return (
    <>
      <Loading loading={loading} setLoading={setLoading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Box>
        <Container>
          <Box
            mb={5}
            sx={{
              background: "#272626",
              borderRadius: "24px",
              width: "100%",
              height: "100%",
              pb: 5,
            }}
          >
            <Typography
              pt={matches ? 3 : 4}
              pl={matches ? 3 : 4}
              sx={{
                fontSize: "24px",
                fontWeight: "700",
                fontFamily: "Raleway",
                color: "#fff",
              }}
            >
              Your Lion Pride
            </Typography>
            {userInfo.teamName.length !== 0 ? (
              <Box
                mb={1.5}
                mt={2}
                gap={1}
                px={matches ? 2 : 4}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box>
                  <TyposBox
                    text1="Lion Pride Name"
                    text2={userInfo.teamName.replace(/www.|.com|https/gi, "")}
                  />
                </Box>
                <TyposBox text1="Members" text2={userInfo.members} />
                <TyposBox
                  text1="Total Staked"
                  text2={toLocalFormat(
                    parseFloat(userInfo.teamAmount).toFixed(2)
                  )}
                />
              </Box>
            ) : (
              <Typography
                pt={2}
                pl={matches ? 3 : 4}
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  fontFamily: "Inter",
                  color: "#D9D9D9",
                }}
              >
                You are not in a Lion Pride yet.
              </Typography>
            )}
            {userInfo?.lead === account && (
              <Box
                px={matches ? 2 : 4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <StyledInput
                  type="text"
                  placeholder="Set a Lion pride Name"
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <Button
                        onClick={changeTeamName}
                        sx={{
                          bgcolor: "#EAB10B",
                          filter:
                            "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3))",
                          color: "#fff",
                          px: 5,
                          py: 1.3,
                          mr: -1.7,
                          borderRadius: "32px",
                          "&:hover": {
                            bgcolor: "#373737",
                          },
                        }}
                      >
                        CONFIRM
                      </Button>
                    ),
                  }}
                />
              </Box>
            )}
          </Box>
          {/* /////////////////////////////////////////////////////////////////////// */}
          <Box
            mb={5}
            sx={{
              background: "#272626",
              borderRadius: "24px",
              width: "100%",
              height: "100%",
              pb: 5,
            }}
          >
            <Typography
              pt={matches ? 3 : 4}
              pl={matches ? 3 : 4}
              sx={{
                fontSize: "24px",
                fontWeight: "700",
                fontFamily: "Raleway",
                color: "#fff",
              }}
            >
              Referrals
            </Typography>
            <Box
              my={1.5}
              px={matches ? 2 : 4}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <TyposBox text1="Your Referrals" text2={userInfo.noOfRefer} />
              <TyposBox
                text1="Total Earned"
                text2={`$${toLocalFormat(
                  parseFloat(userInfo.referReward).toFixed(2)
                )}`}
              />
              <Box px={matches ? 3 : 2} />
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              px={matches ? 2 : 4}
            >
              <StyledInput
                value={
                  account
                    ? window.location.origin + "/dapp/stake/?ref=" + account
                    : "Please connect your wallet."
                }
                readOnly
                InputProps={{
                  endAdornment: (
                    <CopyToClipboard
                      text={
                        account
                          ? window.location.origin +
                            "/dapp/stake/?ref=" +
                            account
                          : ""
                      }
                      onCopy={() =>
                        account
                          ? setAlertState({
                              open: true,
                              message: `Referral address copied to clipboard.`,
                              severity: "success",
                            })
                          : setAlertState({
                              open: true,
                              message: `Please connect your wallet.`,
                              severity: "success",
                            })
                      }
                    >
                      <IconButton
                        sx={{
                          bgcolor: "#EAB10B",
                          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.5)",
                          color: "#fff",
                          px: 1.3,
                          py: 1.3,
                          mr: -1.7,
                          borderRadius: "10px",
                          "&:hover": {
                            bgcolor: "#373737",
                          },
                        }}
                      >
                        <ContentCopyRoundedIcon />
                      </IconButton>
                    </CopyToClipboard>
                  ),
                }}
              />
            </Box>
          </Box>

          {/* ////////////////////////////////////////////////////////////////////////////// */}
          <Box
            mb={5}
            sx={{
              background: "#272626",
              borderRadius: "24px",
              width: "100%",
              height: "100%",
              pb: 5,
            }}
          >
            <Typography
              pt={matches ? 3 : 4}
              pl={matches ? 3 : 4}
              sx={{
                fontSize: "24px",
                fontWeight: "700",
                fontFamily: "Raleway",
                color: "#fff",
              }}
            >
              All Lion Pride
            </Typography>
            <Box px={matches ? 3 : 4} mt={3}>
              <Box
                sx={{
                  background:
                    "linear-gradient(352.62deg, #EAB10B 8.85%, rgba(255, 255, 255, 0.06) 62.12%, rgba(255, 255, 255, 0.15) 68.74%),linear-gradient(0deg, #202225, #202225)",
                  p: "1px",
                  borderRadius: "8px",
                  height: "100%",
                }}
              >
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "none",
                    borderRadius: "8px",
                    background: "#202225",
                  }}
                >
                  <Table sx={{ minWidth: 600 }}>
                    <TableHead
                      sx={{
                        background:
                          "linear-gradient(180.7deg, #EAB10B 41.33%, #F33E5C 147.05%)",
                      }}
                    >
                      <TableRow
                        sx={{
                          borderBottom: "2px solid #1F1F1F",
                        }}
                      >
                        <TableCell align="center">
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#FFF",
                              fontFamily: "Inter",
                              py: 1,
                            }}
                          >
                            Rank
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#FFF",
                              fontFamily: "Inter",
                              py: 1,
                            }}
                          >
                            Lion Pride Name
                          </Typography>
                        </TableCell>

                        <TableCell align="center">
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#FFF",
                              fontFamily: "Inter",
                              py: 1,
                            }}
                          >
                            Members
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#FFF",
                              fontFamily: "Inter",
                              py: 1,
                            }}
                          >
                            Total Staked
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>

                    {teamInfo.length > 0 ? (
                      <TableBody>
                        {teamInfo
                          .slice(
                            currentPage * postsPerPage - postsPerPage,
                            currentPage * postsPerPage
                          )
                          .map(
                            (
                              { lead, percent, amount, count, name, rank },
                              i
                            ) => (
                              <TableRow
                                key={i}
                                sx={{
                                  borderBottom: "2px solid #1F1F1F",
                                }}
                              >
                                <StyledTableCell>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      color: "#D9D9D9",
                                      fontFamily: "Inter",
                                      textAlign: "center",
                                      py: 1,
                                    }}
                                  >
                                    {rank + 1}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      color: "#D9D9D9",
                                      fontFamily: "Inter",
                                      textAlign: "center",
                                      py: 1,
                                    }}
                                  >
                                    {name.replace(/www.|.com|https/gi, "")}
                                  </Typography>
                                </StyledTableCell>

                                <StyledTableCell>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      color: "#D9D9D9",
                                      fontFamily: "Inter",
                                      textAlign: "center",
                                      py: 1,
                                    }}
                                  >
                                    {count}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      color: "#D9D9D9",
                                      fontFamily: "Inter",
                                      textAlign: "center",
                                      py: 1,
                                    }}
                                  >
                                    {toLocalFormat(
                                      parseFloat(amount).toFixed(2)
                                    )}
                                  </Typography>
                                </StyledTableCell>
                                {!exist && (
                                  <StyledTableCell>
                                    <Button
                                      onClick={() => {
                                        navigate(`/dapp/stake/?ref=${lead}`);
                                      }}
                                      disabled={count >= 200 ? true : false}
                                      sx={{
                                        bgcolor: "#505050",
                                        color: "#fff",
                                        px: 2,
                                        borderRadius: "4px",
                                        "&:hover": {
                                          bgcolor: "#505050a1",
                                        },
                                        "&.Mui-disabled": {
                                          background: "red",
                                          color: "#fff",
                                        },
                                      }}
                                    >
                                      {count >= 200 ? "Max" : "Join"}
                                    </Button>
                                  </StyledTableCell>
                                )}
                              </TableRow>
                            )
                          )}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            style={{
                              border: "none",
                            }}
                          >
                            <Box mt={3} textAlign="center">
                              <PulseLoader color="#fff" size={25} />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </Container>
        {teamInfo.length > 0 && (
          <Box my="20px">
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent="center"
            >
              <Pagination
                sx={{
                  "& .MuiPaginationItem-root": {
                    color: "#fff !important",
                    "&.Mui-selected": {
                      backgroundColor: "#EAB10B !important",
                      color: "#ffffff !important",
                    },
                  },
                }}
                count={pageCount}
                page={currentPage}
                onChange={handleChangepage}
              />
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );
}
