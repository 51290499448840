import {
  Alert,
  Box,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Button } from "@mui/material";
import btn from "../../Images/btn.png";

export function ToastNotify({ alertState, setAlertState }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={alertState.open}
      autoHideDuration={10000}
      key={"top center"}
      onClose={() => setAlertState({ ...alertState, open: false })}
    >
      <Alert
        onClose={() => setAlertState({ ...alertState, open: false })}
        severity={alertState.severity}
      >
        {alertState.message}
      </Alert>
    </Snackbar>
  );
}

export function StyledButton({ children, ...props }) {
  return (
    <>
      <Button
        {...props}
        href={props.href}
        sx={{
          color: "#ffffff",
          background:
            "linear-gradient(180.7deg, #EAB10B 41.33%, #F33E5C 147.05%)",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          textTransform: "Capitalize",
          fontSize: "14px",
          fontFamily: "Inter",
          fontWeight: "500",
          borderRadius: "77px",
          height: "44px",
          width: props.width,
          "&.Mui-disabled": {
            color: "#979EA7",
          },
          "&:hover": {
            background:
              "linear-gradient(180.7deg, #EAB10B 41.33%, #F33E5C 147.05%)",
          },
        }}
      >
        {children}
      </Button>
    </>
  );
}

export function StyledText({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: "#fff",
          fontSize: "14px",
          fontFamily: "Inter",
          fontWeight: "500",
          mr: props.mr,
          "&:hover": {
            pb: 0.5,
            borderBottom: "2px solid #EAB10B",
          },
        }}
      >
        {children}
      </Box>
    </>
  );
}
export function StyledTitle({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: "#000000",
          fontSize: "40px",
          //   fontFamily: "Josefin Sans",
          fontWeight: "700",
          mr: props.mr,
        }}
      >
        {children}
      </Box>
    </>
  );
}
export function StyledButtonTwo({ children, ...props }) {
  return (
    <>
      <Button
        disableRipple={true}
        {...props}
        startIcon={props.startIcon}
        href={props.href}
        sx={{
          color: "#ffffff",
          backgroundImage: `url(${btn})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "transparent",
          justifyContent: "center",
          fontSize: { xs: "14px", md: "16px" },
          fontFamily: "Inter",
          fontWeight: "500",
          textTransform: "Capitalize",
          width: props.width,
          height: "46px",
          borderRadius: "30px",
          "&.Mui-disabled": {
            color: "#ffffff",
          },
        }}
      >
        {children}
      </Button>
    </>
  );
}

export const StyledInput = ({ ...props }) => {
  return (
    <TextField
      {...props}
      sx={{
        background:
          "linear-gradient(90deg, rgba(55,55,55,1) 33%, rgba(33,33,33,1) 93%)",
        width: "100%",
        borderRadius: "25px",
        boxShadow: "5px 10px 15px rgba(0, 0, 0, 0.5)",
        "& .MuiOutlinedInput-root": {
          borderRadius: "10px",
          "& fieldset": {
            border: "none",
          },
          "&:hover fieldset": {
            border: "none",
          },
          "&.Mui-focused fieldset": {
            border: "none",
          },
        },

        input: {
          padding: "12px 12px",
          color: "#fff",
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "600",
          zIndex: 9,
        },
      }}
    />
  );
};
export const TyposBox = ({ text1, text2, ...props }) => {
  const check = useMediaQuery("(max-width:700px)");

  return (
    <Box {...props}>
      <Box>
        <Typography
          variant="body2"
          sx={{ fontSize: { xs: "12px", md: "14px" }, color: "#fff" }}
        >
          {text1}
        </Typography>
        <Typography
          mt={-0.5}
          variant="subtitle1"
          sx={{
            fontSize: { xs: "18px", md: "24px" },
            fontWeight: "600",
            textAlign: "center",
            color: "#fff",
          }}
        >
          {text2}
        </Typography>
      </Box>
      <Box></Box>
    </Box>
  );
};
export const TyposBoxsmall = ({ text1, text2, ...props }) => {
  const check = useMediaQuery("(max-width:700px)");

  return (
    <Box {...props}>
      <Box>
        <Typography
          variant="body2"
          sx={{
            fontSize: { xs: "10px", md: "10px" },
            color: "#fff",
            textAlign: "center",
          }}
        >
          {text1}
        </Typography>
        <Typography
          mt={-0.5}
          variant="subtitle1"
          sx={{
            fontSize: { xs: "18px", md: "20px" },
            fontWeight: "500",
            textAlign: "center",
            color: "#fff",
          }}
        >
          {text2}
        </Typography>
      </Box>
      <Box></Box>
    </Box>
  );
};
