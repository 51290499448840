import React from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import sectionbg from "../Images/sectionbg.png";
import mbsectionbg from "../Images/mbsectionbg.png";
import card1 from "../Images/card1.png";
import graph from "../Images/graph.png";
import chart from "../Images/chart.png";

export default function Activity() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${matches ? mbsectionbg : sectionbg})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "start",
          backgroundColor: "transparent",
          height: "100%",
          py: matches ? 13 : 15,
        }}
      >
        <Container maxWidth="lg">
          <Typography
            pb={matches ? 10 : 12}
            sx={{
              fontSize: { xs: "28px", md: "32px" },
              fontWeight: "800",
              fontFamily: "Raleway",
              color: "#fff",
              lineHeight: { xs: "40px", md: "55px" },
              textAlign: "center",
            }}
          >
            Powered by external revenue.
          </Typography>
          <Grid container spacing={7} mt={1}>
            <Grid item xs={12} md={6}>
              <div data-aos="zoom-in-right" duration={5000}>
                <Box
                  sx={{
                    backgroundImage: `url(${card1})`,
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundColor: "transparent",
                    height: matches ? "370px" : "365px",
                    width: matches ? "100%" : "90%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box display="flex" justifyContent="center" mt={-13}>
                    <img src={graph} width="55%" alt="" />
                  </Box>
                  <Typography
                    pt={3}
                    px={3}
                    sx={{
                      fontSize: matches ? "20px" : "24px",
                      fontWeight: "800",
                      fontFamily: "Raleway",
                      color: "#fff",
                      textAlign: "start",
                    }}
                  >
                    ARBITRAGE TRADING BOTS
                  </Typography>
                  <Typography
                    px={3}
                    pt={1}
                    sx={{
                      fontSize: matches ? "12px" : "14px",
                      fontWeight: "500",
                      fontFamily: "Inter",
                      color: "#D9D9D9",
                    }}
                  >
                    Our arbitrage trading bot strategy taps into the power of
                    market inefficiencies to capitalize on price differences
                    across exchanges. By taking advantage of these
                    opportunities, our trading bots can execute profitable
                    trades, and generate at least 3-7% in a week depending on
                    the market conditions. This external revenue coming into
                    Lion Capital serves two purposes, to maximize the return for
                    our members and to reallocate funds back into the Lion
                    Capital portfolio to repeat the process and generate more
                    profit for our members.
                  </Typography>
                  {/* <Typography
                    px={3}
                    py={1}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Inter",
                      color: "#EAB10B",
                    }}
                  >
                    Learn More
                  </Typography> */}
                </Box>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              justifyContent="flex-end"
              mt={matches && 15}
            >
              <div data-aos="zoom-in-left" duration={5000}>
                <Box
                  sx={{
                    backgroundImage: `url(${card1})`,
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundColor: "transparent",
                    height: matches ? "370px" : "365px",
                    width: matches ? "100%" : "90%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box display="flex" justifyContent="center" mt={-13}>
                    <img src={chart} width="55%" alt="" />
                  </Box>
                  <Typography
                    pt={3}
                    px={3}
                    sx={{
                      fontSize: matches ? "20px" : "24px",
                      fontWeight: "800",
                      fontFamily: "Raleway",
                      color: "#fff",
                      textAlign: "start",
                    }}
                  >
                    LIQUIDITY FARMING
                  </Typography>
                  <Typography
                    px={3}
                    pt={1}
                    sx={{
                      fontSize: matches ? "12px" : "14px",
                      fontWeight: "500",
                      fontFamily: "Inter",
                      color: "#D9D9D9",
                    }}
                  >
                    We use liquidity farming to fuel our platform. We take funds
                    from our portfolio and invest in specific DeFi protocols and
                    platforms to add to the liquidity. Our focus is two-fold in
                    this aspect: for the capital we invest, we aim to get a
                    return on investment, and for the DeFi space to grow. While
                    being sustainable for our protocol. Our liquidity farming
                    strategy is returning 4-6%, which is enough to keep our
                    protocol going. Keep in mind that to fuel our Protocol, we
                    need to make at least 1% per week to keep growing.
                  </Typography>
                  {/* <Typography
                    px={3}
                    py={1}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Inter",
                      color: "#EAB10B",
                    }}
                  >
                    Learn More
                  </Typography> */}
                </Box>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
