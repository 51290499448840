import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import sectionbg from "../Images/sectionbg.png";
import mbsectionbg from "../Images/mbsectionbg.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const faq = [
  {
    id: 1,
    question: "What is an ROI Staking Dapp?",
    answer:
      "An ROI Staking Dapp allows you to stake your stable coin on a platform and earn returns daily. At Lion Pride, our ROI ranges from 1% to 3% per day. We've innovated the concept of staking by introducing Lion Pride, where users join Lion Pride to enhance their returns collectively. The base ROI is 1%, and depending on your Lion Pride size and contributions, you can earn an additional 2%.",
  },
  {
    id: 2,
    question: "Is there a minimum lock staking period?",
    answer:
      "Yes, there is. When you deposit funds into our contract, they are locked for 60 days. However, you can claim your rewards every 7 to 10 days.",
  },
  {
    id: 3,
    question: "How often can I claim my rewards?",
    answer:
      "You can claim your rewards every 7 to 10 days. It's important to note that if you don't claim within this period, your dividends will stop accumulating until you do claim. This system encourages users to claim regularly to maximize their earnings.",
  },
  {
    id: 4,
    question:
      "Why would someone join a Lion Pride other than the top 1, 2, or 3?",
    answer:
      "The top Lion Pride is often capped and requires invitations to join. If you're unable to join these top-ranked packs directly, you can still benefit from joining other Prides. Each Lion Pride has its dynamics and potential for growth, offering opportunities for collaboration and collective earning",
  },
  {
    id: 5,
    question: "How does the referral system work?",
    answer:
      "We've made it easy for both parties to enjoy the referral program! When you refer someone to Lion Pride, both you and the referred investor receive a 2%/1% respectively. This serves as a nice incentive to help you grow your Lion Pride.",
  },
];
export default function Faqs() {
  const matches = useMediaQuery("(max-width:950px)");
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box
      my={5}
      sx={{
        backgroundImage: `url(${matches ? mbsectionbg : sectionbg})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "start",
        backgroundColor: "transparent",
        height: "100%",
        py: 10,
      }}
    >
      <Container maxWidth="md">
        <Typography
          sx={{
            fontSize: { xs: "28px", md: "32px" },
            fontWeight: "800",
            fontFamily: "Raleway",
            color: "#fff",
            textAlign: "center",
          }}
        >
          Frequently Asked Questions
        </Typography>
        <Typography
          mt={2}
          sx={{
            fontSize: "18px",
            fontWeight: "500",
            fontFamily: "Inter",
            color: "#D9D9D9",
            textAlign: "center",
          }}
        >
          Find relevant information and quick answers to your questions
        </Typography>
        <Box mt={10} sx={{ width: "100%" }}>
          {faq.map((faq, i) => {
            const { id, question, answer } = faq;
            return (
              <Box key={i} mt={2} borderRadius="5px" sx={{ width: "100%" }}>
                <Accordion
                  expanded={expanded === `{panel${id}}`}
                  onChange={handleChange(`{panel${id}}`)}
                  style={{
                    borderRadius: "12px",
                    background: "#272626",
                    width: "100%",
                    m: "auto",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === `{panel${id}}` ? (
                        <KeyboardArrowUpIcon
                          style={{
                            color: "#fff",
                            fontSize: "30px",
                          }}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          style={{
                            color: "#fff",
                            fontSize: "30px",
                          }}
                        />
                      )
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Box
                      px={2}
                      py={1}
                      fontSize={"14px"}
                      fontFamily="Raleway"
                      fontWeight="700"
                      color="#fff"
                    >
                      {question}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      borderTop="1px solid #737373"
                      pl={2}
                      py={3}
                      fontSize="12px"
                      fontFamily="Inter"
                      fontWeight="400"
                      textAlign="left"
                      color="#fff"
                    >
                      {answer}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            );
          })}
        </Box>
      </Container>
    </Box>
  );
}
