import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { FaTelegramPlane, FaDiscord } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { SiGitbook } from "react-icons/si";
import logo from "../Images/logo.png";
export default function Footer() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <>
      <Container maxWidth="xl">
        <Box
          pt={10}
          sx={{
            px: { xl: 4, lg: 4, md: 0, xs: 0 },
          }}
        >
          {/* <Divider
            sx={{
              background:
                "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255,0.3) 52.28%, rgba(255, 255, 255, 0) 98.33%)",
              height: "1px",
              width: "100%",
            }}
          /> */}
          <Box pb={matches ? 10 : 20} pt={10}>
            <Grid container spacing={3} display="flex" justifyContent="center">
              <Grid
                item
                xs={12}
                md={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <img src={logo} width="270px" alt="" />

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={"center"}
                  mt={2}
                >
                  <a
                    href="https://t.me/lioncapitalofficial"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    rel="noreferrer"
                  >
                    <FaTelegramPlane
                      style={{
                        marginRight: "15px",
                        cursor: "pointer",
                        fontSize: "50px",
                        color: "#fff",
                      }}
                      alt=""
                    />
                  </a>

                  {/* <FaDiscord
                    style={{
                      marginRight: "15px",
                      cursor: "pointer",
                      fontSize: "50px",
                      color: "#fff",
                    }}
                    alt=""
                  /> */}
                  <a
                    href="https://x.com/Lion_Capital_?t=p6UOR6nrzuw2urFf6KyREw&s=09"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    rel="noreferrer"
                  >
                    <BsTwitterX
                      style={{
                        marginRight: "15px",
                        cursor: "pointer",
                        fontSize: "40px",
                        color: "#fff",
                      }}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://trons-organization.gitbook.io/docs/"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    rel="noreferrer"
                  >
                    <SiGitbook
                      style={{
                        // marginRight: "15px",
                        cursor: "pointer",
                        fontSize: "50px",
                        color: "#fff",
                      }}
                      alt=""
                    />
                  </a>
                </Box>
                <Typography
                  mt={3}
                  sx={{
                    fontSize: "18px",
                    fontFamily: "Inter",
                    fontWeight: "400",
                    color: "#565660",
                    textAlign: "center",
                  }}
                >
                  © 2024 Lion Pride. All rights reserved.
                </Typography>
              </Grid>
              {/* <Grid item xs={6} md={1.8} display="flex" justifyContent="center">
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "600",
                      color: "#fff",
                    }}
                  >
                    Home
                  </Typography>
                  <Typography
                    mt={2}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    How it works
                  </Typography>
                  <Typography
                    mt={1}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    Lion Pride
                  </Typography>
                  <Typography
                    mt={1}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    Roadmap
                  </Typography>
                  <Typography
                    mt={1}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    Pro
                  </Typography>
                  <Typography
                    mt={1}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    Partners
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={1.8} display="flex" justifyContent="center">
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "600",
                      color: "#fff",
                    }}
                  >
                    Lion Pride
                  </Typography>
                  <Typography
                    mt={2}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    About
                  </Typography>
                  <Typography
                    mt={1}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    Report
                  </Typography>
                  <Typography
                    mt={1}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    Join fund
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={1.8} display="flex" justifyContent="center">
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "600",
                      color: "#fff",
                    }}
                  >
                    Trading services
                  </Typography>
                  <Typography
                    mt={2}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    How it works
                  </Typography>
                  <Typography
                    mt={1}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    Start earning
                  </Typography>
                  <Typography
                    mt={1}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    FAQ
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={1.8} display="flex" justifyContent="center">
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "600",
                      color: "#fff",
                    }}
                  >
                    Pro
                  </Typography>
                  <Typography
                    mt={2}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    How it works
                  </Typography>
                  <Typography
                    mt={1}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    Pricing
                  </Typography>
                  <Typography
                    mt={1}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    FAQ
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={1.8} display="flex" justifyContent="center">
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "600",
                      color: "#fff",
                    }}
                  >
                    Legal
                  </Typography>
                  <Typography
                    mt={2}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    term of use
                  </Typography>
                  <Typography
                    mt={1}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    Risk disclosure
                  </Typography>
                  <Typography
                    mt={1}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    Privacy policy
                  </Typography>
                  <Typography
                    mt={1}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    Code of ethics
                  </Typography>
                  <Typography
                    mt={1}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    security policy
                  </Typography>
                  <Typography
                    mt={1}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      color: "#D9D9D9",
                    }}
                  >
                    AML policy
                  </Typography>
                </Box>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}
