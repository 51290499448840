import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import slion from "../../Images/slion.png";
import { StyledButton } from "../../components/SmallComponents/AppComponents";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { stakeReadFunction } from "../../ConnectivityAssets/hooks";
import { formatUnits } from "viem";
import Loading from "../../components/SmallComponents/loading";
import Timer from "./Battles/Timer";
const faq = [
  {
    id: 1,
    question: "What is an ROI Staking Dapp?",
    answer:
      "An ROI Staking Dapp allows you to stake your stable coin on a platform and earn returns daily. At Lion Pride, our ROI ranges from 1% to 3% per day. We've innovated the concept of staking by introducing Lion Pride, where users join Lion Pride to enhance their returns collectively. The base ROI is 1%, and depending on your Lion Pride size and contributions, you can earn an additional 2%.",
  },
  {
    id: 2,
    question: "Is there a minimum lock staking period?",
    answer:
      "Yes, there is. When you deposit funds into our contract, they are locked for 60 days. However, you can claim your rewards every 7 to 10 days.",
  },
  {
    id: 3,
    question: "How often can I claim my rewards?",
    answer:
      "You can claim your rewards every 7 to 10 days. It's important to note that if you don't claim within this period, your dividends will stop accumulating until you do claim. This system encourages users to claim regularly to maximize their earnings.",
  },
  {
    id: 4,
    question:
      "Why would someone join a Lion Pride other than the top 1, 2, or 3?",
    answer:
      "The top Lion Pride is often capped and requires invitations to join. If you're unable to join these top-ranked prides directly, you can still benefit from joining other Prides. Each Lion Pride has its dynamics and potential for growth, offering opportunities for collaboration and collective earning",
  },
  {
    id: 5,
    question: "How does the referral system work?",
    answer:
      "We've made it easy for both parties to enjoy the referral program! When you refer someone to Lion Pride, both you and the referred investor receive a 2%/1% respectively. This serves as a nice incentive to help you grow your Lion Pride.",
  },
];
export default function DashboardHome() {
  const matches = useMediaQuery("(max-width:950px)");
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [totalLocked, setTotalLocked] = useState(0);
  const [poolPrize, setPoolPrize] = useState(0);
  const [timeLeft, setTimeLeft] = useState(1);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const init = useCallback(async () => {
    try {
      setLoading(true);

      const totalStaked = await stakeReadFunction("totalStaked");
      setTotalLocked(formatUnits(totalStaked.toString(), 18).toString());

      const calculateWeek = await stakeReadFunction("calculateWeek");
      const launchTime = await stakeReadFunction("launchTime");
      const time =
        (Number(calculateWeek.toString()) + 1) * 420 +
        Number(launchTime.toString());
      setTimeLeft(time);

      const poolPrize = await stakeReadFunction("lotteryPool");
      console.log(+formatUnits(poolPrize.toString(), 18), "poolPrize");
      setPoolPrize(+formatUnits(poolPrize.toString(), 18));
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    init();
  }, [init]);
  return (
    <>
      <Loading loading={loading} setLoading={setLoading} />
      <Box pb={5}>
        <Container maxWidth="lg">
          <Box
            sx={{
              background:
                "linear-gradient(180.7deg, #EAB10B 41.33%, #F33E5C 147.05%)",
              borderRadius: "24px",
              width: "100%",
              height: "100%",
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} md={6}>
                <Typography
                  pl={{ xs: 3, md: 6 }}
                  pr={matches && 3}
                  pt={{ xs: 3, md: 6 }}
                  sx={{
                    fontSize: "26px",
                    fontWeight: "600",
                    color: "#161718",
                    fontFamily: "Raleway",
                  }}
                >
                  The Lion Pride
                </Typography>
                <Typography
                  pt={1}
                  pl={{ xs: 3, md: 6 }}
                  pr={matches && 3}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#202225",
                    fontFamily: "Inter",
                  }}
                >
                  Explore the Lion Pride and unite with other Lions to take
                  advantage of our unique team-staking model that takes yield
                  generation to new heights.
                </Typography>
                <Box
                  my={6}
                  sx={{
                    background: "#202225",
                    borderRadius: "0px 27px 27px 0px",
                    height: "44px",
                    width: "242px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#fff",
                      fontFamily: "Inter",
                    }}
                  >
                    Explore Lion Pride ➜
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                justifyContent="center"
                mt={{ xs: 0, xl: "-4%" }}
              >
                <img src={slion} width={matches ? "70%" : "60%"} alt="" />
              </Grid>
            </Grid>
          </Box>
          {/* ////////////////////////////////Stake/////////////////////////////////////// */}
          <Box
            mt={5}
            pb={3}
            sx={{
              background: "#272626",
              borderRadius: "24px",
              width: "100%",
              height: "100%",
            }}
          >
            <Grid container>
              <Grid item xs={12} md={6} pl={{ xs: 3, md: 6 }} pr={matches && 3}>
                <Typography
                  pt={{ xs: 2, md: 4 }}
                  sx={{
                    fontSize: "26px",
                    fontWeight: "600",
                    color: "#fff",
                    fontFamily: "Raleway",
                  }}
                >
                  Stake
                </Typography>
                <Typography
                  pt={1}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#D9D9D9",
                    fontFamily: "Inter",
                  }}
                >
                  Stake your $USDT with Lion Capital. Experience a{" "}
                  {!matches && <br />}dynamic yield model, driven by real
                  trading profits.
                  {/* {!matches && <br />} Profit distributions are
                  made weekly, every Friday. */}
                </Typography>
                <Box my={3}>
                  <StyledButton width="172px" href="/dapp/stake">
                    Start Staking
                  </StyledButton>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                alignItems="center"
                justifyContent={matches ? "center" : "flex-end"}
                pr={{ xs: 3, md: 6 }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "26px",
                      fontWeight: "600",
                      color: "#fff",
                      fontFamily: "Raleway",
                    }}
                  >
                    Total <span style={{ color: "#EAB10B" }}>$USDT</span> Staked
                  </Typography>
                  <Typography
                    pt={1}
                    sx={{
                      fontSize: "26px",
                      fontWeight: "600",
                      color: "#fff",
                      fontFamily: "Raleway",
                      textAlign: matches ? "center" : "end",
                    }}
                  >
                    {totalLocked}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* ////////////////////////////////Lion Pride Battles///////////////////////////////////// */}
          <Box
            mt={5}
            pb={3}
            sx={{
              background:
                "linear-gradient(180.7deg, #EAB10B 41.33%, #F33E5C 147.05%)",
              borderRadius: "24px",
              width: "100%",
              height: "100%",
            }}
          >
            <Grid container>
              <Grid item xs={12} md={6} pl={{ xs: 3, md: 6 }} pr={matches && 3}>
                <Typography
                  pt={{ xs: 2, md: 4 }}
                  sx={{
                    fontSize: "26px",
                    fontWeight: "600",
                    color: "#161718",
                    fontFamily: "Raleway",
                  }}
                >
                  Lion Pride Battles
                </Typography>
                <Typography
                  pt={1}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#202225",
                    fontFamily: "Inter",
                  }}
                >
                  Engage in our Weekly Lion Pride Battles by staking
                  {!matches && <br />} $USDT. Compete alongside your pride for a
                  share of the {!matches && <br />} weekly prize pool. Triumph
                  brings rewarding yields for {!matches && <br />} your entire
                  team. It's teamwork, the Lion Pride way!
                </Typography>
                <Box my={3}>
                  <StyledButton
                    width="172px"
                    href="/dapp/weekly-battles"
                    style={{
                      background: "#161718",
                      border: "1px solid #EAB10B",
                    }}
                  >
                    Enter Battles
                  </StyledButton>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                alignItems="center"
                justifyContent="center"
                px={{ xs: 3, md: 6 }}
              >
                <Box width={matches ? "100%" : "80%"}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Inter",
                      color: "#161718",
                    }}
                  >
                    Prize Pool
                  </Typography>
                  <Box
                    mt={1}
                    display="flex"
                    alignItems="center"
                    sx={{
                      background: "#161718",
                      borderRadius: "52px",
                      height: "47px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      pl={3}
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        fontFamily: "Inter",
                        color: "#fff",
                      }}
                    >
                      ${parseFloat(poolPrize).toFixed(2)}
                    </Typography>
                  </Box>
                  <Typography
                    mt={2}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Inter",
                      color: "#161718",
                    }}
                  >
                    Time left
                  </Typography>
                  <Box
                    mt={1}
                    display="flex"
                    alignItems="center"
                    sx={{
                      background: "#161718",
                      borderRadius: "52px",
                      height: "47px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      pl={3}
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        fontFamily: "Inter",
                        color: "#fff",
                      }}
                    >
                      <Timer time={timeLeft} />
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* ////////////////////////////////Faqs///////////////////////////////////// */}
          <Box
            mt={5}
            py={3}
            sx={{
              background: "#272626",
              borderRadius: "24px",
              width: "100%",
              height: "100%",
            }}
          >
            <Box px={{ xs: 3, md: 6 }}>
              <Typography
                sx={{
                  fontSize: "26px",
                  fontWeight: "600",
                  color: "#fff",
                  fontFamily: "Raleway",
                }}
              >
                Lion Capital FAQ
              </Typography>

              <Box mt={2} sx={{ width: "100%" }}>
                {faq.map((faq, i) => {
                  const { id, question, answer } = faq;
                  return (
                    <Box
                      key={i}
                      mt={2}
                      borderRadius="12px"
                      sx={{ width: "100%" }}
                    >
                      <Accordion
                        expanded={expanded === `{panel${id}}`}
                        onChange={handleChange(`{panel${id}}`)}
                        style={{
                          borderRadius: "12px",
                          background: " #161718",
                          width: "100%",
                          m: "auto",
                          // p: 1,
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            expanded === `{panel${id}}` ? (
                              <KeyboardArrowUpIcon
                                style={{
                                  color: "#fff",
                                  fontSize: "30px",
                                }}
                              />
                            ) : (
                              <KeyboardArrowDownIcon
                                style={{
                                  color: "#fff",
                                  fontSize: "30px",
                                }}
                              />
                            )
                          }
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Box
                            px={2}
                            py={1}
                            fontSize={"14px"}
                            fontFamily="Raleway"
                            fontWeight="700"
                            color="#fff"
                          >
                            {question}
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box
                            borderTop="1px solid #737373"
                            pl={2}
                            py={3}
                            fontSize="12px"
                            fontFamily="Inter"
                            fontWeight="400"
                            textAlign="left"
                            color="#fff"
                          >
                            {answer}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
