import React, { useContext, useEffect, useState } from "react";
import AdminDrawer from "./Drawer";
import { Backdrop, useMediaQuery } from "@mui/material";
import DashHeader from "./DashHeader";
import TopCards from "./TopCards";
import DashboardHome from "./DashboardHome";
import Stake from "./Stake/Staking";
import { Route, Routes } from "react-router-dom";
import Packs from "./LionPacks/Packs";
import Battles from "./Battles/Battles";
import { AppContext } from "../../utils";
import { useNetwork } from "wagmi";
import NetworkSwitch from "../../NetworkSwitch";

export default function Admin() {
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery("(max-width:950px)");
  const { account } = useContext(AppContext);
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const { chain } = useNetwork();

  useEffect(() => {
    if (account && chain && chain?.id !== 56) {
      setOpenNetworkSwitch(true);
    }
  }, [chain, account]);
  return (
    <div
      style={{
        overflowX: "hidden",
        position: "relative",
        zIndex: 0,
        marginTop: matches ? "0px" : "-40px",
      }}
    >
      <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} />

      <AdminDrawer>
        <Backdrop
          onClick={() => setOpen(false)}
          sx={{ color: "#000000", opacity: "0.8", filter: "blur(8px)" }}
          open={open}
        ></Backdrop>

        {!matches && <DashHeader />}
        <TopCards />
        <Routes>
          <Route path="/" element={<DashboardHome />} />
          <Route path="/stake" element={<Stake />} />
          <Route path="/packs" element={<Packs />} />
          <Route path="/weekly-battles" element={<Battles />} />
        </Routes>
      </AdminDrawer>
    </div>
  );
}
