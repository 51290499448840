import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import cardbg from "../../Images/cardbg.png";
import invest from "../../Images/invest.svg";
import stakeCoin from "../../Images/stakeCoin.svg";
import Capital from "../../Images/capital.svg";
import people from "../../Images/people.svg";
import { stakeReadFunction } from "../../ConnectivityAssets/hooks";
import { formatUnits } from "viem";
import Loading from "../../components/SmallComponents/loading";

export default function TopCards() {
  const [loading, setLoading] = useState(false);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalLocked, setTotalLocked] = useState(0);
  const [users, setUsers] = useState(0);
  const [teams, setTeams] = useState(0);

  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const init = useCallback(async () => {
    try {
      setLoading(true);
      const investment = await stakeReadFunction("getContractBalance");
      const totalStaked = await stakeReadFunction("totalStaked");
      const stakers = await stakeReadFunction("uniqueStakers");
      const teams = await stakeReadFunction("uniqueTeamId");

      setTotalInvestment(formatUnits(investment.toString(), 18).toString());
      setTotalLocked(formatUnits(totalStaked.toString(), 18).toString());
      setUsers(stakers.toString());
      setTeams(teams.toString());
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    init();
  }, [init]);

  const cardsData = [
    {
      img: invest,
      title: "Total Investments",
      amount: `$ ${toLocalFormat(Math.floor(totalInvestment)) ?? 0.0}`,
    },
    {
      img: stakeCoin,
      title: "Total Staked",
      amount: `$ ${toLocalFormat(Math.floor(totalLocked)) ?? 0.0}`,
    },
    {
      img: people,
      title: "Total Users",
      amount: toLocalFormat(users),
    },
    {
      img: Capital,
      title: "Total Lion Pride",
      amount: toLocalFormat(teams),
    },
  ];
  return (
    <>
      <Loading loading={loading} setLoading={setLoading} />
      <Box py={5}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            {cardsData.map(({ img, title, amount }, index) => {
              return (
                <Grid item xs={12} md={3} key={index}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      backgroundImage: `url(${cardbg})`,
                      backgroundSize: "100% 100%",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "start",
                      backgroundColor: "transparent",
                      height: "87px",
                    }}
                  >
                    <img
                      src={img}
                      width={index === 3 ? "40px" : "30px"}
                      height={index === 3 ? "40px" : "30px"}
                      alt=""
                    />
                    <Box ml={2}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#D9D9D9",
                          fontFamily: "Inter",
                        }}
                      >
                        {title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "23px",
                          fontWeight: "600",
                          color: "#fff",
                          fontFamily: "Raleway",
                        }}
                      >
                        {amount}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>
    </>
  );
}
