import React from "react";
import {
  Container,
  Hidden,
  useMediaQuery,
  SwipeableDrawer,
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import { logo } from "./SmallComponents/Images";

// import { AppContext } from "../utils";
import { StyledButton, StyledText } from "./SmallComponents/AppComponents";
import { Link } from "react-router-dom";

const mobileData = [
  {
    text: "Home",
    link: "/",
  },
  {
    text: "Fund",
    link: "/",
  },
  {
    text: "Trading",
    link: "/",
  },
  {
    text: "Banking",
    link: "/",
  },
  {
    text: "News",
    link: "/",
  },
];
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
    alignItems: "center",
  },
  paper: {
    background: "#000000 !important",
  },
  hover: {
    "&:hover": {
      color: "#FFB800",
    },
  },
});

export default function Header() {
  // const { account, connect, disconnect } = useContext(AppContext);
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });
  const matches1 = useMediaQuery("(max-width:1279px)");

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box display="flex" justifyContent="center" my={5}>
        <img src={logo} width="170px" alt="" />
      </Box>
      <List>
        {mobileData.map(({ text, link }, index) => (
          <ListItem
            button
            style={{
              justifyContent: "center",
            }}
            key={index}
          >
            <ListItemText
              style={{
                textTransform: "Capitalize",
                textAlign: "center",
                textDecoration: "none",
                cursor: "pointer",
                color: "#ffffff",
              }}
              primary={text}
            />
          </ListItem>
        ))}
      </List>
      <Box mb={1} display="flex" justifyContent="center">
        <Link to="/dapp" style={{ textDecoration: "none" }}>
          <StyledButton width="140px"> Enter dApp</StyledButton>
        </Link>
      </Box>
    </div>
  );
  return (
    <>
      <Box
        sx={{
          background: "transparent",
          py: 2,
        }}
        height="92px"
        width="100%"
      >
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              px: { xl: 4, lg: 4, md: 0, xs: 0 },
            }}
          >
            <img src={logo} width="170px" alt="" />
            <Box
              display="flex"
              justifyContent={matches1 ? "end" : "space-between"}
              alignItems="center"
            >
              {/* <Hidden mdDown> */}
              {/* <StyledText mr={6}>Home</StyledText>
                <StyledText mr={6}>Fund</StyledText>
                <StyledText mr={6}>Trading</StyledText>
                <StyledText mr={6}>Banking</StyledText>
                <StyledText mr={6}>News</StyledText> */}
              <Link to="/dapp" style={{ textDecoration: "none" }}>
                <StyledButton width="140px"> Enter dApp</StyledButton>
              </Link>
              {/* </Hidden> */}

              {/* <Hidden mdUp>
                {["left"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button
                      onClick={toggleDrawer(anchor, true)}
                      style={{ zIndex: 1 }}
                    >
                      <MenuIcon
                        style={{
                          fontSize: "38px",
                          cursor: "pointer",
                          color: "#fff",
                        }}
                      ></MenuIcon>
                    </Button>
                    <Paper>
                      <SwipeableDrawer
                        classes={{ paper: classes.paper }}
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                      >
                        {list(anchor)}
                      </SwipeableDrawer>
                    </Paper>
                  </React.Fragment>
                ))}
              </Hidden> */}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
