import { Backdrop, Box, Typography } from "@mui/material";

import loader from "../../Images/loader.jpg";

const load = 50;

export default function Loading({ loading }) {
  const loaderStyle = {
    background: `conic-gradient(from 180deg at 70% 30%, rgba(234, 177, 11, 1) 0%, rgba(243, 62, 92, 1) ${load}%, #101012 ${load}%)`,
  };

  return (
    <Backdrop
      sx={{
        zIndex: 1000,
        color: "#fff",
        //bg bulr
        backdropFilter: "blur(20px)",
        background: "#161719a1",
      }}
      open={loading}
    >
      <Box className="loading-box">
        <Box className="loading-circle" style={loaderStyle}>
          <Typography className="loading-count">
            <Box display="flex" justifyContent="center" alignContent={"center"}>
              <img
                src={loader}
                alt="mini"
                style={{ borderRadius: "50%" }}
                width="90%"
              />
            </Box>
          </Typography>
        </Box>
      </Box>
    </Backdrop>
  );
}

// import Backdrop from "@mui/material/Backdrop";
// import { makeStyles } from "@mui/styles";
// import { ClockLoader } from "react-spinners";

// const useStyles = makeStyles(() => ({
//   backdrop: {
//     zIndex: 220000000,
//     color: "#fff",
//   },
// }));

// export default function Loading({ loading }) {
//   const classes = useStyles();
//   return (
//     <div>
//       <Backdrop className={classes.backdrop} open={loading}>
//         <ClockLoader color="#EAB10B" size={150} />
//       </Backdrop>
//     </div>
//   );
// }
