import { Box, Container, useMediaQuery } from "@mui/material";
import React from "react";
import Marquee from "react-fast-marquee";
import dapp from "../Images/dapp.svg";
import partner2 from "../Images/partner2.png";
import partner3 from "../Images/partner3.png";
import partner4 from "../Images/partner4.png";
import partner5 from "../Images/partner5.png";
import partner6 from "../Images/partner6.png";
import partner7 from "../Images/partner7.png";
export default function Partners() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <Box
      py={3}
      mb={5}
      sx={{
        background: "#000000",
      }}
    >
      {/* <Marquee
        speed={50}
        pauseOnClick={true}
        pauseOnHover={true}
        gradient={false}
      > */}
      <Container maxWidth="xl">
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          width="100%"
        >
          <img
            src={dapp}
            width="197px"
            // style={{ marginRight: "90px" }}
            alt=""
          />

          {/* <img
              src={partner2}
              //   width="247px"
              style={{ marginRight: "90px" }}
              alt=""
            />  */}

          {/* <img
              src={partner3}
              //   width="247px"
              style={{ marginRight: matches ? "90px" : "170px" }}
              alt=""
            />

            <img
              src={partner4}
              width="150px"
              style={{ marginRight: matches ? "90px" : "170px" }}
              alt=""
            /> */}
          {/* <img
              src={partner5}
              //   width="247px"
              style={{ marginRight: "90px" }}
              alt=""
            /> */}

          {/* <img
              src={partner6}
              //   width="247px"
              style={{ marginRight: matches ? "90px" : "170px" }}
              alt=""
            />

            <img
              src={partner7}
              //  width="317px"
              // style={{ marginRight: "290px" }}
              alt=""
            /> */}
        </Box>
      </Container>
      {/* </Marquee> */}
    </Box>
  );
}
