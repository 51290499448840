import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useContext, useEffect } from "react";
import usdt from "../../../Images/usdt.png";
import { BsFillInfoCircleFill } from "react-icons/bs";
import {
  StyledButton,
  ToastNotify,
  TyposBox,
} from "../../../components/SmallComponents/AppComponents";
import { useState } from "react";
import { CustomizedSteppers } from "./Stepper";
import deposite from "../../../Images/deposite.svg";
import stakeCoin from "../../../Images/stakeCoin.svg";
import claim from "../../../Images/claim.svg";
import withdraw from "../../../Images/withdraw.svg";
import profit from "../../../Images/profit.svg";
import { formatUnits, parseUnits } from "viem";
import {
  stakeReadFunction,
  stakeWriteFunction,
  tokenReadFunction,
  tokenWriteFunction,
} from "../../../ConnectivityAssets/hooks";
import { stakeAddress } from "../../../ConnectivityAssets/environment";
import { AppContext } from "../../../utils";
import Loading from "../../../components/SmallComponents/loading";
import moment from "moment";
import { PulseLoader } from "react-spinners";
import Timer from "../Battles/Timer";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    padding: "16px",
  },
}));

const packsInfo = [
  {
    rank: "2nd",
    name: "Alpha ",
    count: "123",
    amount: "$289",
  },
  {
    rank: "2nd",
    name: "Alpha ",
    count: "123",
    amount: "$289",
  },
  {
    rank: "2nd",
    name: "Alpha ",
    count: "123",
    amount: "$289",
  },
];

export default function Staking() {
  const matches = useMediaQuery("(max-width:950px)");
  const [days, setDays] = useState(1);
  const [timeLeft, setTimeLeft] = useState(1);
  const [loading, setLoading] = useState(false);
  const { account } = useContext(AppContext);
  const [amount, setAmount] = useState("");
  const [calAmount, setCalAmount] = useState("");
  const [calPercent, setCalPercent] = useState("");
  const [exist, setExist] = useState(false);
  const [stakeData, setStakeData] = useState([]);
  const [totalClaimableReward, setTotalClaimableReward] = useState(0);
  const [totalActiveCards, setTotalActiveCards] = useState(0);
  const [totalWithdraw, setTotalWithdraw] = useState(0);
  const [totalStake, setTotalStake] = useState(0);
  const [currentStake, setCurrentStake] = useState(0);
  const [userInfo, setUserInfo] = useState({
    teamName: "",
    members: 0,
    teamAmount: 0,
    timeLeft: 0,
    currentStake: 0,
    currentWeek: 0,
    totalStake: 0,
    totalWithdraw: 0,
    reward: 0,
    stakeCount: 0,
  });

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };

  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const stakeHandler = useCallback(async () => {
    try {
      if (!account) {
        return showAlert("Please connect your wallet.");
      }

      if (amount <= 0) {
        return showAlert("Please enter the amount to stake.");
      }
      setLoading(true);

      // const balance = await tokenContract.balanceOf(account);
      const balance = await tokenReadFunction("balanceOf", [account]);
      if (+formatUnits(balance.toString(), 18) < amount) {
        setLoading(false);
        return showAlert("Insufficient $USDT balance.");
      }
      const allowance = await tokenReadFunction("allowance", [
        account,
        stakeAddress,
      ]);
      // const allowance = await tokenContract.allowance(account, stakeAddress);
      const allowanceWithoutDecimal = +formatUnits(allowance.toString(), 18);

      if (allowanceWithoutDecimal < amount) {
        await tokenWriteFunction("approve", [
          stakeAddress,
          "1000000000000000000000000000",
        ]);
        // let tx = await tokenWriteFunction.approve(
        //   stakeAddress,
        //   "1000000000000000000000000000"
        // );
        // await tx.wait();
      }

      let refer = "";
      if (window.location.href.includes("?ref=")) {
        let getAddress = window.location.href.split("?ref=")[1];
        refer = getAddress.slice(0, 42);
      } else {
        refer = "0x0000000000000000000000000000000000000000";
      }
      await stakeWriteFunction("stake", [
        refer,
        parseUnits(amount.toString(), 18),
      ]);
      console.log(amount, "amount");
      console.log(refer, "refer");
      // const tx = await contract.stake(refer, parseUnits(amount.toString(), 18));
      // await tx.wait();

      // await drawerInit();
      await fetchUserInfo();
      await stakesCardData();
      setAmount(0);
      setAlertState({
        open: true,
        message: `Stake successful!`,
        severity: "success",
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      const errorMessage = error?.shortMessage;
      showAlert(errorMessage);
    }
    // eslint-disable-next-line no-use-before-define
  }, [account, amount]);
  ////////////////////////////////////////////////
  const fetchUserInfo = useCallback(async () => {
    try {
      setLoading(true);
      const value = await stakeReadFunction("getUserInfo", [account]);
      const _currentStaked = +formatUnits(value[6].toString(), 18);
      const currentWeek = await stakeReadFunction("currentWeek");

      const launchTime = await stakeReadFunction("launchTime");

      const _totalStaked = +formatUnits(value[7].toString(), 18);
      const _stakeCount = Number(value[1].toString());
      const _totalWithdrawan = +formatUnits(value[8].toString(), 18);
      const _teamId = value[5].toString();
      const _isExists = value[0];

      if (!_isExists && window.location.href.includes("?ref=")) {
        const getAddress = window.location.href.split("?ref=")[1];
        const referalAddress = getAddress.slice(0, 42);
        const value = await stakeReadFunction("getUserInfo", [referalAddress]);
        const _teamId = value[5].toString();

        const userTeam = await stakeReadFunction("getTeamInfo", [_teamId]);

        // console.log(userTeam, "userTeam");

        const _teamAmount = +formatUnits(userTeam[3].toString(), 18);
        const _teamCount = Number(userTeam[2].toString());
        const _teamName = userTeam[1];

        setExist(_isExists);
        setUserInfo({
          teamName: _teamName,
          members: _teamCount,
          teamAmount: _teamAmount,
        });
        setLoading(false);
        return;
      }
      const userTeam = await stakeReadFunction("getTeamInfo", [_teamId]);
      const _teamAmount = +formatUnits(userTeam[3].toString(), 18);
      const _teamCount = Number(userTeam[2].toString());
      const _teamName = userTeam[1];
      const _currentPercent = Number(userTeam[4].toString());

      const basePercent = await stakeReadFunction("basePercent");
      const time =
        (Number(currentWeek.toString()) + 1) * 604800 +
        Number(launchTime.toString());
      setTimeLeft(time);
      console.log(time, "time");
      const totalPercentage =
        (Number(basePercent.toString()) + _currentPercent) / 100;

      const reward = (+_currentStaked * +totalPercentage) / 100;

      setExist(_isExists);
      setUserInfo({
        teamName: _teamName,
        members: _teamCount,
        teamAmount: _teamAmount,
        timeLeft: time,
        currentStake: _currentStaked,
        currentWeek: Number(currentWeek.toString()),
        totalStake: _totalStaked + (_totalStaked * 11.1112) / 100,
        totalWithdraw: _totalWithdrawan,
        reward,
        stakeCount: _stakeCount,
      });
      setTotalStake(_totalStaked + (_totalStaked * 11.1112) / 100);
      setCurrentStake(_currentStaked);
      setTotalWithdraw(_totalWithdrawan);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, [account]);

  ////////////////////////////////////////////////
  const stakesCardData = useCallback(async () => {
    try {
      const value = await stakeReadFunction("getUserInfo", [account]);
      const _stakeCount = Number(value[1].toString());
      console.log(_stakeCount, "_stakeCount");
      let data = [];
      let claimableTotalReward = 0;
      let totalCards = 0;
      let requestTime = 0;
      for (let i = 0; i < _stakeCount; i++) {
        const userTokenInfo = await stakeReadFunction("getUserTokenStakeInfo", [
          account,
          i.toString(),
        ]);

        const _amount = +formatUnits(userTokenInfo[0].toString(), 18);
        const _checkpoint = Number(userTokenInfo[1].toString());
        const _startTime = Number(userTokenInfo[3].toString());
        const _isActive = userTokenInfo[4];
        // requestTime = userTokenInfo[2];

        const lockDuration = await stakeReadFunction("lockDuration");
        const claimDuration = await stakeReadFunction("claimDuration");
        const currentTime = moment().unix();
        const calTime = currentTime - _checkpoint;
        // // accumulation calculation

        const oneDaySeconds = 86400;

        const currentUnixTime = moment().unix();

        const accumulateTime = Number(lockDuration.toString()) + _startTime;

        const accumulateLeftTime = +accumulateTime - +currentUnixTime;
        console.log(accumulateLeftTime, "accumulateLeftTime");
        let accumulateDay = 0;
        let barPercentage = 0;
        if (accumulateLeftTime > 0) {
          const time = accumulateLeftTime / oneDaySeconds;
          accumulateDay = Math.floor(time);
          barPercentage =
            (+accumulateLeftTime / Number(lockDuration.toString())) * 100;
        }
        // /////////////////////////////

        const reward = await stakeReadFunction("calculateReward", [account, i]);

        const claimEndTime = Number(claimDuration.toString()) + _checkpoint;
        const claimEndTimeValue =
          moment.unix(claimEndTime).format("x") - moment().format("x");
        if (claimEndTimeValue <= 0 && _isActive) {
          claimableTotalReward =
            +claimableTotalReward + +formatUnits(reward.toString(), 18);
        }
        if (_isActive) {
          totalCards += 1;
        }
        const obj = {
          amount: _amount,
          reward: +formatUnits(reward.toString(), 18),
          barPercentage,
          remainTime: accumulateTime,
          claimEndTime: claimEndTime,
          stakeEndTime: Number(lockDuration.toString()) + _startTime,
          active: _isActive,
          calTime: calTime,
          claimDuration: claimDuration.toString(),
        };
        data.push(obj);
      }
      setTotalActiveCards(totalCards);
      setStakeData([...data]);
      console.log([...data], "datattatatat");
      setTotalClaimableReward(claimableTotalReward);
    } catch (e) {
      console.log(e);
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      fetchUserInfo();
      stakesCardData();
    }
  }, [account, fetchUserInfo, stakesCardData]);

  const cardsData = [
    {
      img: deposite,
      title: "Total Deposit",
      amount: `$ ${parseFloat(totalStake).toFixed(2)}`,
    },
    {
      img: stakeCoin,
      title: "Currently Staked",
      amount: `$ ${parseFloat(currentStake).toFixed(2)}`,
    },
    {
      img: claim,
      title: "Claimable Profit",
      amount: `$ ${parseFloat(totalClaimableReward).toFixed(2)}`,
    },
    {
      img: withdraw,
      title: "Total Withdrawn ",
      amount: `$ ${parseFloat(totalWithdraw).toFixed(2)}`,
    },
    {
      img: stakeCoin,
      title: "Active Stakes",
      amount: ` ${totalActiveCards}`,
    },
    // {
    //   img: profit,
    //   title: "Next Profit Distribution",
    //   amount: <Timer time={timeLeft} />,
    // },
  ];

  const claimReward = useCallback(
    async (i) => {
      try {
        if (!account) {
          return showAlert("Please connect your wallet.");
        }
        setLoading(true);
        await stakeWriteFunction("claim", [i]);

        await fetchUserInfo();
        await stakesCardData();

        setAlertState({
          open: true,
          message: `Claimed successful!`,
          severity: "success",
        });

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
        const errorMessage = error?.shortMessage;
        showAlert(errorMessage);
      }
    },
    [account, fetchUserInfo, stakesCardData]
  );

  const withdrawHandler = useCallback(
    async (i) => {
      try {
        if (!account) {
          return showAlert("Please connect your wallet.");
        }
        setLoading(true);
        await stakeWriteFunction("withdraw", [i]);

        await fetchUserInfo();
        await stakesCardData();

        setAlertState({
          open: true,
          message: `withdraw successful!`,
          severity: "success",
        });

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
        const errorMessage = error?.shortMessage;
        showAlert(errorMessage);
      }
    },
    [account, fetchUserInfo, stakesCardData]
  );

  const unStakedAllHandler = useCallback(async () => {
    try {
      if (!account) {
        return showAlert("Please connect your wallet.");
      }
      setLoading(true);
      await stakeWriteFunction("withdrawAll");

      await fetchUserInfo();
      await stakesCardData();

      setAlertState({
        open: true,
        message: `Unstaked All successful!`,
        severity: "success",
      });

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      const errorMessage = error?.shortMessage;
      showAlert(errorMessage);
    }
  }, [account, fetchUserInfo, stakesCardData]);

  const mergeAllHandler = useCallback(async () => {
    try {
      if (!account) {
        return showAlert("Please connect your wallet.");
      }
      setLoading(true);
      await stakeWriteFunction("mergeAll");

      await fetchUserInfo();
      await stakesCardData();

      setAlertState({
        open: true,
        message: `Merged All successful!`,
        severity: "success",
      });

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      const errorMessage = error?.shortMessage;
      showAlert(errorMessage);
    }
  }, [account, fetchUserInfo, stakesCardData]);

  const claimAllHandler = useCallback(async () => {
    try {
      if (!account) {
        return showAlert("Please connect your wallet.");
      }
      setLoading(true);
      await stakeWriteFunction("claimAll");

      await fetchUserInfo();
      await stakesCardData();

      setAlertState({
        open: true,
        message: `Claimed All successful!`,
        severity: "success",
      });

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      const errorMessage = error?.shortMessage;
      showAlert(errorMessage);
    }
  }, [account, fetchUserInfo, stakesCardData]);
  return (
    <>
      <Loading loading={loading} setLoading={setLoading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Box>
        <Container>
          <Box
            sx={{
              background: "#272626",
              borderRadius: "24px",
              width: "100%",
              height: "100%",
            }}
          >
            <Box p={matches ? 2 : 3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      background:
                        "linear-gradient(91.18deg, #EAB10B 1.38%, rgba(0, 0, 0, 0) 22.82%, rgba(0, 0, 0, 0) 81.76%, #EAB10B 100.61%)",
                      borderRadius: "24px",
                      width: "100%",
                      height: "330px",
                      p: "1px",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#1c1c1d",
                        borderRadius: "24px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Box p={matches ? 2 : 3}>
                        <Typography
                          sx={{
                            fontSize: "20px",
                            fontWeight: "600",
                            fontFamily: "Raleway",
                            color: "#fff",
                          }}
                        >
                          Stake
                        </Typography>
                        <Typography
                          pt={2}
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "Inter",
                            color: "#fff",
                          }}
                        >
                          Enter amount to stake
                        </Typography>
                        <Box
                          mt={2}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{
                            background: "#242424",
                            border: "1px solid rgba(255, 255, 255, 0.66)",
                            borderRadius: "52px",
                            height: "47px",
                            width: "100%",
                          }}
                        >
                          <Box display="flex" alignItems="center">
                            <Box
                              mr={2}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              sx={{
                                background: "#EAB10B",
                                width: "69px",
                                height: "47px",
                                borderRadius: "52px 0px  0px  52px",
                              }}
                            >
                              <img src={usdt} width="29px" alt="" />
                            </Box>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                fontFamily: "Inter",
                                color: "#EAB10B",
                              }}
                            >
                              USDT
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          mt={4}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{
                            background: "#242424",
                            border: "1px solid rgba(255, 255, 255, 0.66)",
                            borderRadius: "52px",
                            height: "47px",
                            width: "100%",
                          }}
                        >
                          <input
                            type="number"
                            style={{
                              height: "40px",
                              width: "90%",
                              fontSize: "14px",
                              fontWeight: "400",
                              textAlign: "left",
                              color: "#fff",
                              fontFamily: "Inter",
                              backgroundColor: "transparent",
                              paddingLeft: "25px",
                              border: "none",
                              outline: "none",
                            }}
                            value={amount}
                            onChange={(e) =>
                              e.target.value.match(/^[0-9]*[.]?[0-9]*$/)
                                ? setAmount(e.target.value)
                                : ""
                            }
                            placeholder="Enter Amount"
                          />
                        </Box>
                        <Box mt={3} display="flex" justifyContent="center">
                          <StyledButton
                            width="223px"
                            onClick={() => stakeHandler()}
                          >
                            Deposit
                          </StyledButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    mt={2}
                    sx={{
                      background:
                        "linear-gradient(91.18deg, #EAB10B 1.38%, rgba(0, 0, 0, 0) 22.82%, rgba(0, 0, 0, 0) 81.76%, #EAB10B 100.61%)",
                      borderRadius: "24px",
                      width: "100%",
                      height: "144px",
                      p: "1px",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#1c1c1d",
                        borderRadius: "24px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Box p={matches ? 2 : 3}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography
                            sx={{
                              fontSize: "20px",
                              fontWeight: "600",
                              fontFamily: "Raleway",
                              color: "#fff",
                            }}
                          >
                            <span style={{ color: "#EAB10B" }}>Lion Pride</span>{" "}
                          </Typography>
                          <BsFillInfoCircleFill
                            style={{ fontSize: "25px", color: "#EAB10B" }}
                          />
                        </Box>

                        {exist || window.location.href.includes("?ref") ? (
                          <Box
                            mt={2}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: {
                                    xs: "12px",
                                    md: "14px",
                                    color: "#fff",
                                  },
                                }}
                              >
                                Lion Pride Name
                              </Typography>
                              <Typography
                                mt={-0.5}
                                variant="subtitle1"
                                sx={{
                                  fontSize: { xs: "18px", md: "24px" },
                                  fontWeight: "600",
                                  textAlign: "left",
                                  width: { xs: "125px", md: "180px" },
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  color: "#fff",
                                }}
                              >
                                {userInfo.teamName.replace(
                                  /www.|.com|https/gi,
                                  ""
                                )}
                              </Typography>
                            </Box>

                            <TyposBox
                              text1="Members"
                              text2={userInfo.members}
                            />
                            <TyposBox
                              text1="Total Staked"
                              text2={toLocalFormat(
                                parseFloat(userInfo.teamAmount).toFixed(2)
                              )}
                            />
                          </Box>
                        ) : (
                          <Typography
                            pt={2}
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              fontFamily: "Inter",
                              color: "#fff",
                            }}
                          >
                            You are not in a Lion Pride.
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      background:
                        "linear-gradient(91.18deg, #EAB10B 1.38%, rgba(0, 0, 0, 0) 22.82%, rgba(0, 0, 0, 0) 81.76%, #EAB10B 100.61%)",
                      borderRadius: "24px",
                      width: "100%",
                      height: "100%",
                      p: "1px",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#1c1c1d",
                        borderRadius: "24px",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Box p={matches ? 2 : 3}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography
                            sx={{
                              fontSize: "20px",
                              fontWeight: "600",
                              fontFamily: "Raleway",
                              color: "#fff",
                            }}
                          >
                            Calculator
                          </Typography>
                          <BsFillInfoCircleFill
                            style={{ fontSize: "25px", color: "#EAB10B" }}
                          />
                        </Box>

                        <Typography
                          pt={2}
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "Inter",
                            color: "#fff",
                          }}
                        >
                          Staked amount
                        </Typography>
                        <Box
                          mt={2}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{
                            background: "#242424",
                            border: "1px solid rgba(255, 255, 255, 0.66)",
                            borderRadius: "52px",
                            height: "47px",
                            width: "100%",
                          }}
                        >
                          <input
                            type="number"
                            style={{
                              height: "40px",
                              width: "90%",
                              fontSize: "14px",
                              fontWeight: "400",
                              textAlign: "left",
                              color: "#fff",
                              fontFamily: "Inter",
                              backgroundColor: "transparent",
                              paddingLeft: "25px",
                              border: "none",
                              outline: "none",
                            }}
                            value={calAmount}
                            onChange={(e) =>
                              e.target.value.match(/^[0-9]*[.]?[0-9]*$/)
                                ? setCalAmount(e.target.value)
                                : ""
                            }
                            placeholder="0.00"
                          />
                        </Box>
                        <Typography
                          pt={3}
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "Inter",
                            color: "#fff",
                          }}
                        >
                          Weekly %
                        </Typography>
                        <Box
                          mt={2}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{
                            background: "#242424",
                            border: "1px solid rgba(255, 255, 255, 0.66)",
                            borderRadius: "52px",
                            height: "47px",
                            width: "100%",
                          }}
                        >
                          <input
                            type="number"
                            // readOnly
                            style={{
                              height: "40px",
                              width: "90%",
                              fontSize: "14px",
                              fontWeight: "400",
                              textAlign: "left",
                              color: "#fff",
                              fontFamily: "Inter",
                              backgroundColor: "transparent",
                              paddingLeft: "25px",
                              border: "none",
                              outline: "none",
                            }}
                            value={calPercent}
                            onChange={(e) => {
                              if (
                                e.target.value >= 0 &&
                                e.target.value <= 10 &&
                                e.target.value.match(/^[0-9]*[.]?[0-9]*$/)
                              ) {
                                setCalPercent(e.target.value);
                              }
                            }}
                            placeholder="0.00"
                          />
                        </Box>
                        <Typography
                          py={3}
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "Inter",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Period: {days} Weeks
                        </Typography>
                        <Box display="flex" justifyContent="center">
                          <Slider
                            value={days}
                            onChange={(e) => setDays(e.target.value)}
                            min={1}
                            max={52}
                            sx={{
                              width: "90%",
                              color: "#fff",
                              "& .MuiSlider-thumb": {
                                width: "22px",
                                background:
                                  "linear-gradient(180.7deg, #EAB10B 41.33%, #F33E5C 147.05%)",
                                height: "22px",
                              },
                              "& .MuiSlider-rail": {
                                height: "2px",
                                background: "#ffff",
                                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.5)",
                                borderRadius: "19px",
                              },
                              "& .MuiSlider-track": {
                                height: "1px",
                                background: "#74757B",
                                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.5)",
                                borderRadius: "19px",
                              },
                            }}
                          />
                        </Box>
                        <Box
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Box width="45%">
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                fontFamily: "Inter",
                                color: "#fff",
                              }}
                            >
                              Total Profit
                            </Typography>
                            <Box
                              mt={1}
                              pl={2}
                              sx={{
                                background: "#242424",
                                height: "45px",
                                fontSize: "20px",
                                fontWeight: "500",
                                fontFamily: "Inter",
                                color: "#fff",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {`$ ${toLocalFormat(
                                (
                                  parseFloat((calAmount * calPercent) / 100) *
                                  days
                                ).toFixed(2)
                              )}`}
                            </Box>
                          </Box>
                          <Box width="45%">
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                fontFamily: "Inter",
                                color: "#fff",
                              }}
                            >
                              Weekly Profit
                            </Typography>
                            <Box
                              mt={1}
                              pl={2}
                              sx={{
                                background: "#242424",
                                height: "45px",
                                fontSize: "20px",
                                fontWeight: "500",
                                fontFamily: "Inter",
                                color: "#fff",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {`$ ${toLocalFormat(
                                parseFloat(
                                  (calAmount * calPercent) / 100
                                ).toFixed(2)
                              )}`}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* ////////////////////////////////ROI Withdrawal Request////////////////////////////////////// */}

          {/* <Box
            my={5}
            sx={{
              background: "#272626",
              borderRadius: "24px",
              width: "100%",
              height: "100%",
              pb: 5,
            }}
          >
            <Typography
              pt={matches ? 3 : 4}
              pl={matches ? 3 : 4}
              sx={{
                fontSize: "24px",
                fontWeight: "700",
                fontFamily: "Raleway",
                color: "#fff",
              }}
            >
              ROI Withdrawal Request
            </Typography>
            <Box
              mt={3}
              py={4}
              sx={{
                background: "#161718",
              }}
            >
              <CustomizedSteppers />
            </Box>
            <Box mt={4} textAlign="center">
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  fontFamily: "Inter",
                  color: "#fff",
                }}
              >
                Eligible ROI Amount
              </Typography>
              <Typography
                mb={2}
                sx={{
                  fontSize: "24px",
                  fontWeight: "700",
                  fontFamily: "Inter",
                  color: "#fff",
                }}
              >
                $0.00
              </Typography>

              <StyledButton width="220px">Request Withdrawal</StyledButton>
            </Box>
          </Box> */}

          {/* ////////////////////////////////Your Staking Portfolio////////////////////////////////////// */}
          <Box
            mt={5}
            sx={{
              background: "#272626",
              borderRadius: "24px",
              width: "100%",
              height: "100%",
              pb: 5,
            }}
          >
            <Typography
              pt={matches ? 3 : 4}
              pl={matches ? 3 : 4}
              sx={{
                fontSize: "24px",
                fontWeight: "700",
                fontFamily: "Raleway",
                color: "#fff",
              }}
            >
              Your Staking Portfolio
            </Typography>
            <Container maxWidth="md">
              <Grid
                container
                spacing={3}
                mt={2}
                display="flex"
                justifyContent="center"
              >
                {cardsData.map(({ img, title, amount }, index) => {
                  return (
                    <Grid item xs={12} md={4}>
                      <Box
                        sx={{
                          height: "80px",
                          background:
                            "linear-gradient(92.56deg, #EAB10B -12.83%, rgba(255, 255, 255, 0.06) 92.04%, rgba(255, 255, 255, 0.15) 105.06%),linear-gradient(0deg, #161718, #161718)",
                          p: "1px",
                          borderRadius: "10px",
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{
                            height: "100%",
                            background: "#161718",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            src={img}
                            width={index === 2 || index === 5 ? "42px" : "32px"}
                            height={
                              index === 2 || index === 5 ? "42px" : "32px"
                            }
                            style={{ marginLeft: "20px" }}
                            alt=""
                          />
                          <Box ml={2}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#D9D9D9",
                                fontFamily: "Inter",
                              }}
                            >
                              {title}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: index === 5 ? "18px" : "23px",
                                fontWeight: "600",
                                color: "#fff",
                                fontFamily: "Raleway",
                              }}
                            >
                              {amount}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Container>
            <Container maxWidth="sm">
              <Box
                display="flex"
                alignItems="center"
                flexDirection={matches ? "column" : "row"}
                justifyContent="center"
                mt={5}
              >
                <StyledButton width="163px" onClick={unStakedAllHandler}>
                  Unstake All
                </StyledButton>
                <StyledButton
                  width="163px"
                  onClick={mergeAllHandler}
                  style={{
                    border: "1px solid #EAB10B",
                    background: "#161718",
                    marginLeft: matches ? "0px" : "20px",
                    marginTop: matches ? "20px" : "0px",
                  }}
                >
                  Merge
                </StyledButton>
                <StyledButton
                  width="163px"
                  onClick={claimAllHandler}
                  style={{
                    border: "1px solid #EAB10B",
                    background: "#161718",
                    marginLeft: matches ? "0px" : "20px",
                    marginTop: matches ? "20px" : "0px",
                  }}
                >
                  Claim
                </StyledButton>
              </Box>
            </Container>
          </Box>
          {/* //////////////////////////////// Stakes////////////////////////////////////// */}
          <Box
            my={5}
            sx={{
              background: "#272626",
              borderRadius: "24px",
              width: "100%",
              height: "100%",
              pb: 5,
            }}
          >
            <Typography
              pt={matches ? 3 : 4}
              pl={matches ? 3 : 4}
              sx={{
                fontSize: "24px",
                fontWeight: "700",
                fontFamily: "Raleway",
                color: "#fff",
              }}
            >
              Your Stakes
            </Typography>

            <Box px={matches ? 3 : 4} mt={3}>
              {stakeData.length > 0 ? (
                <Box
                  sx={{
                    background:
                      "linear-gradient(352.62deg, #EAB10B 8.85%, rgba(255, 255, 255, 0.06) 62.12%, rgba(255, 255, 255, 0.15) 68.74%),linear-gradient(0deg, #202225, #202225)",
                    p: "1px",
                    borderRadius: "8px",
                    height: "100%",
                  }}
                >
                  <TableContainer
                    component={Paper}
                    sx={{
                      boxShadow: "none",
                      borderRadius: "8px",
                      background: "#202225",
                    }}
                  >
                    <Table sx={{ minWidth: 1000 }}>
                      <TableHead
                        sx={{
                          background:
                            "linear-gradient(180.7deg, #EAB10B 41.33%, #F33E5C 147.05%)",
                        }}
                      >
                        <TableRow
                          sx={{
                            borderBottom: "2px solid #1F1F1F",
                          }}
                        >
                          <TableCell align="center">
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#FFF",
                                fontFamily: "Inter",
                                py: 1,
                              }}
                            >
                              STAKE
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#FFF",
                                fontFamily: "Inter",
                                py: 1,
                              }}
                            >
                              Staked Amount
                            </Typography>
                          </TableCell>

                          <TableCell align="center">
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#FFF",
                                fontFamily: "Inter",
                                py: 1,
                              }}
                            >
                              Claimable Profit
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#FFF",
                                fontFamily: "Inter",
                                py: 1,
                              }}
                            >
                              Stake Unlock
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#FFF",
                                fontFamily: "Inter",
                                py: 1,
                              }}
                            >
                              Withdrawal
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#FFF",
                                fontFamily: "Inter",
                                py: 1,
                              }}
                            >
                              Claim
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {stakeData.map(
                          (
                            {
                              amount,
                              reward,
                              claimEndTime,
                              remainTime,
                              stakeEndTime,
                              active,
                              claimDuration,
                              calTime,
                            },
                            i
                          ) => {
                            console.log(
                              claimDuration,
                              "claimDuration",
                              calTime,
                              "calTime"
                            );
                            const stakeEndtimeValue =
                              moment.unix(+stakeEndTime).format("x") -
                              moment().format("x");
                            const date = new Date(remainTime * 1000);
                            console.log(remainTime, "remainTime");
                            // Convert the Date object to GMT using toUTCString()
                            const gmtTime = date.toUTCString();
                            return (
                              <React.Fragment key={i}>
                                {active && (
                                  <TableRow
                                    // key={i}
                                    sx={{
                                      borderBottom: "2px solid #737373B8",
                                    }}
                                  >
                                    <StyledTableCell>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                          color: "#D9D9D9",
                                          fontFamily: "Inter",
                                          textAlign: "center",
                                          py: 1,
                                        }}
                                      >
                                        {mergeAllHandler ? i : i + 1}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                          color: "#D9D9D9",
                                          fontFamily: "Inter",
                                          textAlign: "center",
                                          py: 1,
                                        }}
                                      >
                                        {toLocalFormat(
                                          parseFloat(amount).toFixed(2)
                                        )}
                                      </Typography>
                                    </StyledTableCell>

                                    <StyledTableCell>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                          color: "#D9D9D9",
                                          fontFamily: "Inter",
                                          textAlign: "center",
                                          py: 1,
                                        }}
                                      >
                                        {toLocalFormat(
                                          parseFloat(reward).toFixed(2)
                                        )}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                          color: "#D9D9D9",
                                          fontFamily: "Inter",
                                          textAlign: "center",
                                          py: 1,
                                        }}
                                      >
                                        {/* {accumulateDay === 0
                                          ? "MAX"
                                          : accumulateDay <= 1
                                          ? "01 DAY LEFT"
                                          : `${accumulateDay} DAYS LEFT`} */}
                                        {gmtTime}
                                      </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Button
                                        onClick={() => withdrawHandler(i)}
                                        sx={{
                                          background:
                                            "rgba(255, 255, 255, 0.2)",
                                          boxShadow:
                                            "0px 4px 10px rgba(0, 0, 0, 0.25), inset 3px -3px 4px rgba(244, 211, 255, 0.1)",
                                          backdropFilter: "blur(3.5px)",
                                          borderRadius: "10px",
                                          // display: requestTime === 0 && "none",
                                          color: "#fff",
                                          width: "100%",
                                          height: "33px",
                                        }}
                                      >
                                        withdraw
                                      </Button>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Button
                                        onClick={() =>
                                          claimReward(i, claimEndTime)
                                        }
                                        sx={{
                                          background:
                                            "rgba(255, 255, 255, 0.2)",
                                          boxShadow:
                                            "0px 4px 10px rgba(0, 0, 0, 0.25), inset 3px -3px 4px rgba(244, 211, 255, 0.1)",
                                          backdropFilter: "blur(3.5px)",
                                          borderRadius: "10px",
                                          color: "#fff",
                                          width: "100%",
                                          height: "33px",
                                          position: "relative",
                                        }}
                                      >
                                        Claim
                                        <Box
                                          sx={{
                                            border:
                                              reward > 0
                                                ? "1px solid #252525"
                                                : "1px solid #fff",
                                            borderRadius: "50%",
                                            filter:
                                              reward > 0 &&
                                              "drop-shadow(0px 1.87342px 2.81013px rgba(255, 157, 157, 0.5))",
                                            width: "7px",
                                            height: "7px",
                                            backgroundColor:
                                              +claimDuration >= +calTime
                                                ? "#ff3737"
                                                : "#80ff00",
                                            position: "absolute",
                                            bottom: "2px",
                                            right: "5px",
                                          }}
                                        />
                                      </Button>
                                    </StyledTableCell>
                                  </TableRow>
                                )}
                              </React.Fragment>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              ) : (
                <Box
                  my={5}
                  display={"flex"}
                  justifyContent="center"
                  width="100%"
                >
                  <PulseLoader color="#fff" size={25} />
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
