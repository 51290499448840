import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";

import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import tweetbg from "../Images/tweetbg.png";
import lionNft from "../Images/lionNft.png";

import { Link } from "react-router-dom";

export default function Unleash() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <Box
      sx={{
        backgroundImage: `url(${tweetbg})`,
        backgroundSize: matches ? "0% 0%" : "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "transparent",
        height: "100%",
      }}
    >
      {/* <Container maxWidth="lg">
        <Typography
          sx={{
            fontSize: { xs: "28px", md: "32px" },
            fontWeight: "800",
            fontFamily: "Raleway",
            color: "#fff",
            lineHeight: { xs: "40px", md: "55px" },
            textAlign: "center",
          }}
        >
          Unleash the Pro in you
        </Typography>
        <Typography
          mt={2}
          sx={{
            fontSize: "18px",
            fontWeight: "500",
            fontFamily: "Inter",
            color: "#D9D9D9",
            textAlign: "center",
          }}
        >
          Get to know the Lion Pride Pro subscription and benefit from an
          {!matches && <br />} ecosystem of financial features, products and
          services.
        </Typography>

        <Grid container spacing={5} mt={1}>
          <Grid item xs={6} md={2.4}>
            <StyledButtonTwo
              width={matches ? "100%" : "173px"}
              startIcon={
                <VscGraph style={{ fontSize: "20px", color: "#fff" }} />
              }
            >
              Lion Pride
            </StyledButtonTwo>
          </Grid>
          <Grid item xs={6} md={2.4}>
            <StyledButtonTwo
              width={matches ? "100%" : "173px"}
              startIcon={
                <LuWarehouse style={{ fontSize: "20px", color: "#fff" }} />
              }
            >
              Banking
            </StyledButtonTwo>
          </Grid>
          <Grid item xs={6} md={2.4}>
            <StyledButtonTwo
              width={matches ? "100%" : "193px"}
              startIcon={
                <SlGraph style={{ fontSize: "20px", color: "#fff" }} />
              }
            >
              {matches ? "Services" : "Trading Services"}
            </StyledButtonTwo>
          </Grid>
          <Grid item xs={6} md={2.4}>
            <StyledButtonTwo
              width={matches ? "100%" : "174px"}
              startIcon={<FaBell style={{ fontSize: "20px", color: "#fff" }} />}
            >
              Trade Alerts
            </StyledButtonTwo>
          </Grid>
          <Grid item xs={6} md={2.4}>
            <StyledButtonTwo
              width={matches ? "100%" : "170px"}
              startIcon={
                <FaDiscord style={{ fontSize: "20px", color: "#fff" }} />
              }
            >
              Pro Discord
            </StyledButtonTwo>
          </Grid>
          <Grid item xs={6} md={3} display="flex" justifyContent="flex-end">
            <StyledButtonTwo
              width={matches ? "100%" : "201px"}
              startIcon={
                <FaVideo style={{ fontSize: "20px", color: "#fff" }} />
              }
            >
              {matches ? "videos" : "Weekly TA Videos"}
            </StyledButtonTwo>
          </Grid>
          <Grid item xs={6} md={3}>
            <StyledButtonTwo
              width={matches ? "100%" : "197px"}
              startIcon={
                <MdDateRange style={{ fontSize: "20px", color: "#fff" }} />
              }
            >
              {matches ? "Events" : "Special Events"}
            </StyledButtonTwo>
          </Grid>
          <Grid item xs={6} md={3}>
            <StyledButtonTwo
              width={matches ? "100%" : "207px"}
              startIcon={
                <ImNewspaper style={{ fontSize: "20px", color: "#fff" }} />
              }
            >
              {matches ? "News" : "Exclusive News"}
            </StyledButtonTwo>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            display="flex"
            justifyContent={matches && "center"}
          >
            <StyledButtonTwo
              width={"199px"}
              startIcon={
                <FaHandshake style={{ fontSize: "20px", color: "#fff" }} />
              }
            >
              Partner Deals
            </StyledButtonTwo>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="center" mt={8}>
          <Box
            sx={{
              background:
                "linear-gradient(180.7deg, #EAB10B 41.33%, #F33E5C 147.05%)",
              borderRadius: "8px",
              height: "100%",
              width: matches ? "100%" : "70%",
              display: "flex",
              justifyContent: matches ? "center" : "space-between",
              alignItems: "center",
              flexDirection: matches ? "column" : "row",
              px: 3,
              py: 3,
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "Raleway",
                fontWeight: "500",
                color: "#fff",
              }}
            >
              Monthly plans starts at $200
            </Typography>
            <Typography
              mt={matches && 1}
              sx={{
                fontSize: "14px",
                fontFamily: "Inter",
                fontWeight: "500",
                color: "#fff",
              }}
            >
              Explore & Compare
            </Typography>
            <StyledButton
              width="160px"
              style={{
                background: "#202225",
                marginTop: matches && "20px",
              }}
            >
              Subscribe
            </StyledButton>
          </Box>
        </Box>
      </Container> */}

      <Box
        sx={{
          // mt: 10,
          py: matches ? 5 : 0,
          background: "#070707",
        }}
      >
        <Typography
          pt={matches ? 5 : 10}
          sx={{
            fontSize: { xs: "40px", md: "53px" },
            fontWeight: "800",
            fontFamily: "Raleway",
            color: "#fff",
            lineHeight: { xs: "40px", md: "55px" },
            textAlign: "center",
          }}
        >
          TRANSPARENCY
        </Typography>
        <Typography
          mt={2}
          sx={{
            fontSize: "18px",
            fontWeight: "500",
            fontFamily: "Inter",
            color: "#D9D9D9",
            textAlign: "center",
          }}
        >
          Real-time tracking of all trades ensures transparency and
          accountability
        </Typography>
        <Container>
          <Grid container spacing={2.5} mt={3}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  background:
                    "linear-gradient(126.44deg, #101010 13.12%, #242424 52.72%)",
                  border: "1.5px solid #3E3E3E",
                  boxShadow: "5px 10px 50px rgba(0, 0, 0, 0.8)",
                  borderRadius: "30px",
                  height: "55.5%",
                  py: { xs: 5, md: 4, xl: 5 },
                  px: { xs: 3, md: 3 },
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: { xs: "18px", xl: "24px" },
                    color: "#a5a5a5",
                    textTransform: "uppercase",
                  }}
                >
                  Monthly Capped Deposits
                </Typography>

                <Typography
                  mt={2}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    fontFamily: "Inter",
                    color: "#D9D9D9",
                  }}
                >
                  The monthly deposit limit is now capped at $1 million, meaning
                  any money you deposit within the current month over that limit
                  won't be accepted until the next. And it's there for a reason,
                  the deposit cap is necessary to make sure that trading
                  resources are "appropriately" allocated across different
                  assets and marketplaces.
                </Typography>
              </Box>
              <Box
                sx={{
                  background:
                    "linear-gradient(126.44deg, #101010 13.12%, #242424 52.72%)",
                  border: "1.5px solid #3E3E3E",
                  boxShadow: "5px 10px 50px rgba(0, 0, 0, 0.8)",
                  borderRadius: "30px",
                  // height: "205px",
                  py: { xs: 5, md: 4, xl: 5 },
                  px: { xs: 3, md: 3 },
                  mt: { xs: 5, md: 3 },
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: { xs: "18px", xl: "24px" },
                    color: "#a5a5a5",
                    textTransform: "uppercase",
                  }}
                >
                  Join the Pride Community
                </Typography>

                <Typography
                  mt={2}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    fontFamily: "Inter",
                    color: "#D9D9D9",
                  }}
                >
                  Join our Pride, where lionhearted spirits converge to shape
                  the future of decentralized finance. Experience the power of
                  collective growth as we pioneer innovation in the world of
                  DeFi.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  background:
                    "linear-gradient(126.44deg, #101010 13.12%, #242424 52.72%)",
                  border: "1.5px solid #3E3E3E",
                  boxShadow: "5px 10px 50px rgba(0, 0, 0, 0.8)",
                  borderRadius: "30px",
                  height: "55.5%",
                  py: { xs: 5, md: 4, xl: 5 },
                  px: { xs: 3, md: 3 },
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: { xs: "18px", xl: "24px" },
                    color: "#a5a5a5",
                    textTransform: "uppercase",
                  }}
                >
                  AUDIT & KYC
                </Typography>

                <Typography
                  mt={2}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    fontFamily: "Inter",
                    color: "#D9D9D9",
                  }}
                >
                  Investor trust is paramount at Lion Capital. We will undergo
                  rigorous audit and identity verification with Solid Proof to
                  uphold the highest standards of transparency.
                </Typography>
                <Box display="flex" alignItems="cnter">
                  <Link
                    href=""
                    rel="noreferrer"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "30px",
                    }}
                  >
                    <span style={{ color: "#a5a5a5" }}>AUDIT</span>{" "}
                    <ArrowOutwardIcon
                      sx={{ fontSize: "25px", color: "#a5a5a5" }}
                    />
                  </Link>
                  <Link
                    href=""
                    rel="noreferrer"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    <span style={{ color: "#a5a5a5" }}>KYC</span>{" "}
                    <ArrowOutwardIcon
                      sx={{ fontSize: "25px", color: "#a5a5a5" }}
                    />
                  </Link>
                </Box>
              </Box>
              <Box
                sx={{
                  background:
                    "linear-gradient(126.44deg, #101010 13.12%, #242424 52.72%)",
                  border: "1.5px solid #3E3E3E",
                  boxShadow: "5px 10px 50px rgba(0, 0, 0, 0.8)",
                  borderRadius: "30px",
                  // height: "205px",
                  py: { xs: 5, md: 4, xl: 5 },
                  px: { xs: 3, md: 3 },
                  mt: { xs: 5, md: 3 },
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: { xs: "18px", xl: "24px" },
                    color: "#a5a5a5",
                    textTransform: "uppercase",
                  }}
                >
                  Telegram
                </Typography>

                <Typography
                  mt={2}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    fontFamily: "Inter",
                    color: "#D9D9D9",
                  }}
                >
                  Join our Telegram channel for regular updates.
                </Typography>
                <Link
                  to="https://t.me/lioncapitalofficial"
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    marginTop: "23px",
                  }}
                >
                  <span style={{ color: "#a5a5a5" }}>TELEGRAM CHANNEL</span>{" "}
                  <ArrowOutwardIcon
                    sx={{ fontSize: "25px", color: "#a5a5a5" }}
                  />
                </Link>
              </Box>
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    background:
                      "linear-gradient(126.44deg, #101010 13.12%, #242424 52.72%)",
                    border: "1.5px solid #3E3E3E",
                    boxShadow: "5px 10px 50px rgba(0, 0, 0, 0.8)",
                    borderRadius: "30px",
                    py: { xs: 5, md: 4, xl: 5 },
                    px: { xs: 3, md: 3 },
                    display: "flex",
                    width: matches ? "100%" : "49%",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: { xs: "18px", xl: "24px" },
                        color: "#a5a5a5",
                      }}
                    >
                      TELEGRAM
                    </Typography>

                    <Typography
                      mt={2}
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        fontFamily: "Inter",
                        color: "#D9D9D9",
                      }}
                    >
                      Join our Telegram channel for regular updates.
                    </Typography>
                    <Link
                      to="https://t.me/lioncapitalofficial"
                      rel="noreferrer"
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "30px",
                      }}
                    >
                      <span style={{ color: "#a5a5a5" }}>TELEGRAM CHANNEL</span>{" "}
                      <ArrowOutwardIcon
                        sx={{ fontSize: "25px", color: "#a5a5a5" }}
                      />
                    </Link>
                  </Box>
                </Box>
                <Box
                  sx={{
                    background:
                      "linear-gradient(126.44deg, #101010 13.12%, #242424 52.72%)",
                    border: "1.5px solid #3E3E3E",
                    boxShadow: "5px 10px 50px rgba(0, 0, 0, 0.8)",
                    borderRadius: "30px",
                    py: { xs: 5, md: 4, xl: 5 },
                    px: { xs: 3, md: 3 },
                    p: { xs: 3, md: 3 },
                    ml: { xs: 0, md: 2 },
                    mt: { xs: 3, md: 0 },
                    display: "flex",
                    width: matches ? "100%" : "49%",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: { xs: "18px", xl: "24px" },
                        color: "#a5a5a5",
                      }}
                    >
                      AUDIT & KYC
                    </Typography>

                    <Typography
                      mt={1}
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        fontFamily: "Inter",
                        color: "#D9D9D9",
                      }}
                    >
                      Investor trust is paramount at Lion Capital. We will
                      undergo rigorous audit and identity verification with
                      Solid Proof to uphold the highest standards of
                      transparency.
                    </Typography>

                    <Link
                      href=""
                      rel="noreferrer"
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "30px",
                      }}
                    >
                      <span style={{ color: "#a5a5a5" }}>AUDIT</span>{" "}
                      <ArrowOutwardIcon
                        sx={{ fontSize: "25px", color: "#a5a5a5" }}
                      />
                    </Link>
                    <Link
                      href=""
                      rel="noreferrer"
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "30px",
                      }}
                    >
                      <span style={{ color: "#a5a5a5" }}>KYC</span>{" "}
                      <ArrowOutwardIcon
                        sx={{ fontSize: "25px", color: "#a5a5a5" }}
                      />
                    </Link>
                  </Box>
                </Box>
              </Box>

              
            </Grid> */}
          </Grid>
        </Container>
        <Container maxWidth="xl">
          <Grid
            container
            spacing={3}
            display="flex"
            alignItems="center"
            py={matches ? 5 : 10}
          >
            <Grid item xs={12} md={5}>
              <div data-aos="fade-right">
                <Typography
                  pl={!matches && 8}
                  sx={{
                    fontSize: { xs: "20px", md: "30px" },
                    fontWeight: "800",
                    fontFamily: "Raleway",
                    color: "#fff",
                    lineHeight: { xs: "40px", md: "55px" },
                    textAlign: matches ? "center" : "start",
                  }}
                >
                  Mane NFTs: Limited {!matches && <br />} Collection, Unlimited
                  Potential
                </Typography>
                <Typography
                  pl={!matches && 8}
                  mt={2}
                  sx={{
                    fontSize: "17px",
                    fontWeight: "500",
                    fontFamily: "Inter",
                    color: "#D9D9D9",
                    textAlign: matches ? "center" : "start",
                  }}
                >
                  Enter the heart of Lion capital's pride with our Exclusive
                  Mane NFTs. Limited to only 200, each NFT grants access to
                  exclusive perks such as boosted staking rewards, invitations
                  to VIP events, and a stake in our forthcoming Lion's Den
                  Gaming Platform.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={7} display="flex" justifyContent="center">
              <div data-aos="fade-up">
                <img src={lionNft} width={matches ? "100%" : "100%"} alt="" />
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* <Container>
        <Typography
          mt={matches ? 10 : 15}
          sx={{
            fontSize: { xs: "28px", md: "32px" },
            fontWeight: "800",
            fontFamily: "Raleway",
            color: "#fff",
            lineHeight: { xs: "40px", md: "55px" },
            textAlign: "center",
          }}
        >
          We're on{" "}
          <span
            style={{
              color: "#EAB10B",
            }}
          >
            X!
          </span>
        </Typography>
        <Typography
          my={2}
          sx={{
            fontSize: "18px",
            fontWeight: "500",
            fontFamily: "Inter",
            color: "#D9D9D9",
            textAlign: "center",
          }}
        >
          Check out some of our recent tweets
        </Typography>
        <img src={tweets} width="100%" alt="" />
      </Container> */}
    </Box>
  );
}
