import React from "react";
import styled from "@emotion/styled";
import {
  Box,
  Stack,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  stepConnectorClasses,
  useMediaQuery,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import DoneIcon from "@mui/icons-material/Done";

const ColorlibConnector = styled(StepConnector)({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    // top: 13,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#5865F2",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#5865F2",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: "#737373",
    borderRadius: 2,
  },
});

const ColorlibStepIconRoot = styled("div")({
  zIndex: 1,
  color: "#000",
  width: 30,
  height: 30,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
});

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {completed || active ? (
        <Box
          width="46px"
          height="46px"
          sx={{
            background:
              "linear-gradient(162.7deg, #EAB10B 41.33%, #F33E5C 147.05%)",
          }}
          borderRadius="50%"
          p={1}
        >
          <DoneIcon
            sx={{
              fontSize: "30px",
              color: "#000",
            }}
          />
        </Box>
      ) : (
        <Box
          width="46px"
          height="46px"
          bgcolor="#000"
          border="1px solid #EAB10B"
          borderRadius="50%"
          p={1}
        >
          <CircleIcon
            sx={{
              fontSize: "30px",
              color: "#000",
            }}
          />
        </Box>
      )}
    </ColorlibStepIconRoot>
  );
}
const steps = ["Withdrawal Request", "Processing", "Processed "];
export const CustomizedSteppers = ({ refundState }) => {
  //   console.log(refundState, "ll");
  return (
    <Stack sx={{ width: "100%" }}>
      <Stepper
        alternativeLabel
        activeStep={refundState ? 1 : 0}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, ind) => (
          <Step key={label}>
            <StepLabel
              sx={{
                "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
                  fontSize: { xs: "14px", md: "14px" },
                  fontWeight: "500",
                  fontFamily: "Inter",
                  color: "#fff",
                  mt: { xs: 4, md: 4 },
                },
                "& .MuiStepLabel-label.Mui-active": {
                  color: "#fff",
                },
                "& .MuiStepLabel-label.Mui-completed": {
                  color: "#fff",
                },
              }}
              StepIconComponent={ColorlibStepIcon}
            >
              {`${label}`}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};
