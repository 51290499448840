import React from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import hero from "../Images/heroimg.png";
import { StyledButton } from "../components/SmallComponents/AppComponents";
import { Link } from "react-router-dom";

function HeroSection() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <>
      <Container maxWidth="xl">
        <Box
          sx={{
            px: { xl: 4, lg: 4, md: 0, xs: 0 },
          }}
          pb={10}
        >
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12} md={5} mt={matches ? 0 : 10}>
              <div data-aos="fade-right">
                <Typography
                  sx={{
                    fontSize: { xs: "40px", md: "53px" },
                    fontWeight: "800",
                    fontFamily: "Raleway",
                    color: "#fff",
                    lineHeight: { xs: "40px", md: "55px" },
                    textAlign: matches ? "center" : "start",
                  }}
                >
                  Welcome to <br />
                  <span style={{ color: "#EAB10B" }}>Lion Capital</span>
                </Typography>
                <Typography
                  mt={2}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    fontFamily: "Inter",
                    color: "#D9D9D9",
                    textAlign: matches ? "center" : "start",
                  }}
                >
                  {!matches && <br />} Take the opportunity to join us on a bold
                  journey of revolution and innovation. As part of a team that
                  is passionate about shaping the future of DeFi, you will be a
                  force to be reckoned with. Step into the arena and make your
                  voice heard.
                  <span style={{ fontWeight: "700", color: "#fff" }}>
                    {" "}
                    Roaring into the Future of Staking.
                  </span>
                </Typography>
                <Box
                  mt={4}
                  display="flex"
                  flexDirection={matches ? "column" : "row"}
                  alignItems="center"
                >
                  <StyledButton
                    width={matches ? "80%" : "170px"}
                    style={{
                      background: "#161718",
                      border: "1px solid #EAB10B",
                      marginRight: !matches && "20px",
                    }}
                  >
                    Explore Lion Pride
                  </StyledButton>
                  <StyledButton
                    href="/dapp"
                    style={{
                      marginTop: matches && "20px",
                    }}
                    width={matches ? "80%" : "140px"}
                  >
                    Enter dApp
                  </StyledButton>
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} md={7} display="flex" justifyContent="center">
              <div data-aos="fade-up">
                <img src={hero} width={matches ? "100%" : "100%"} alt="" />
              </div>
            </Grid>
          </Grid>

          <Box
            mt={matches ? 0 : -0.5}
            sx={{
              width: "100%",
              height: "100%",
              background: "#161718",
              border: "1px solid",
              borderImage:
                "linear-gradient(91.18deg, #EAB10B 1.38%, rgba(0, 0, 0, 0) 22.82%, rgba(0, 0, 0, 0) 81.76%, #DD8C56 100.61%)",
              borderImageSlice: "1",
            }}
          >
            <Grid container spacing={3} p={matches ? 1 : 1.5}>
              <Grid
                item
                xs={12}
                md={3.33}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography
                  pt={3}
                  sx={{
                    fontSize: "19px",
                    fontWeight: "800",
                    fontFamily: "Raleway",
                    color: "#fff",
                  }}
                >
                  $12,543,907
                </Typography>
                <Typography
                  mt={1}
                  pb={3}
                  sx={{
                    fontSize: "13px",
                    fontWeight: "500",
                    fontFamily: "Raleway",
                    color: "#D9D9D9",
                  }}
                >
                  Total Investments
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Divider
                  sx={{
                    background:
                      "linear-gradient(93.49deg, rgba(234, 177, 11, 0) -16.16%, rgba(234, 177, 11, 0.66) 33.71%, rgba(234, 177, 11, 0.75) 44.75%, rgba(234, 177, 11, 0.322933) 76.77%)",
                    height: matches ? "2px" : "80%",
                    width: matches ? "80%" : "1px",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3.33}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography
                  pt={3}
                  sx={{
                    fontSize: "19px",
                    fontWeight: "800",
                    fontFamily: "Raleway",
                    color: "#fff",
                  }}
                >
                  3,209
                </Typography>
                <Typography
                  mt={1}
                  pb={3}
                  sx={{
                    fontSize: "13px",
                    fontWeight: "500",
                    fontFamily: "Raleway",
                    color: "#D9D9D9",
                  }}
                >
                  Investors
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Divider
                  sx={{
                    background:
                      "linear-gradient(93.49deg, rgba(234, 177, 11, 0) -16.16%, rgba(234, 177, 11, 0.66) 33.71%, rgba(234, 177, 11, 0.75) 44.75%, rgba(234, 177, 11, 0.322933) 76.77%)",
                    height: matches ? "2px" : "80%",
                    width: matches ? "80%" : "1px",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3.33}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography
                  pt={3}
                  sx={{
                    fontSize: "19px",
                    fontWeight: "800",
                    fontFamily: "Raleway",
                    color: "#fff",
                  }}
                >
                  286
                </Typography>
                <Typography
                  mt={1}
                  pb={3}
                  sx={{
                    fontSize: "13px",
                    fontWeight: "500",
                    fontFamily: "Raleway",
                    color: "#D9D9D9",
                  }}
                >
                  Lion Pride
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default HeroSection;
