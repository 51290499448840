import {
  Box,
  Container,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import left from "../Images/left.png";
import right from "../Images/right.png";
import roadbg from "../Images/roadbg.png";
import mobileRoad from "../Images/mobileRoad.png";

export default function Roadmap() {
  const matches = useMediaQuery("(max-width:1050px)");

  return (
    <Box
      py={5}
      sx={{
        backgroundImage: `url(${matches ? mobileRoad : roadbg})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "transparent",
        height: "100%",
      }}
    >
      <Container maxWidth="md">
        <Typography
          sx={{
            fontSize: { xs: "28px", md: "32px" },
            fontWeight: "400",
            fontFamily: "Raleway",
            color: "#fff",
            textAlign: "center",
          }}
        >
          <span style={{ fontWeight: "800" }}>Lion</span> Capital{" "}
          <span style={{ color: "#EAB10B", fontWeight: "800" }}>Roadmap</span>
        </Typography>
        <Typography
          mt={2}
          sx={{
            fontSize: "18px",
            fontWeight: "500",
            fontFamily: "Inter",
            color: "#D9D9D9",
            textAlign: "center",
          }}
        >
          One strategic step at a time. Forward always.
        </Typography>
        <Box mt={10} className="scrolling-vertical-timeline">
          <VerticalTimeline
            animate={matches ? false : true}
            layout={"2-columns"}
            lineColor="linear-gradient(93.5deg, #F38D3E 4.94%, rgba(241, 142, 67, 0.19) 99.18%)            "
          >
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                backgroundColor: "transparent",
                color: "#000",
                border: "none",
                borderRadius: "20px",
                padding: "0px",
                boxShadow: "none",
              }}
              contentArrowStyle={{ display: "none" }}
              iconStyle={{
                border: "none",
                marginLeft: matches ? "0px" : "-30px",
                marginTop: matches ? "60px" : "60px",
                outline: "none",
                boxShadow: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={
                <Box
                  sx={{
                    mt: -12,
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    border: "5px solid #ed8c24 ",
                    boxSadow: "0px 0px 12px 1px #EAB10B9C",
                    background: "#000000",
                    padding: "5px",
                  }}
                />
              }
            >
              <Box
                mt={3}
                display="flex"
                justifyContent={matches ? "flex-start" : "flex-end"}
                mr={matches ? 0 : -7}
                ml={matches ? -4 : 0}
              >
                {matches && <img src={right} width="140px" alt="" />}
                <Box
                  mt={-2}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "700",
                    fontFamily: "Raleway",
                    color: "#fff",
                  }}
                >
                  2023 - Phase One <br />
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Inter",
                      color: "#D9D9D9",
                    }}
                  >
                    Idea
                  </span>
                </Box>
                {!matches && <img src={left} width="171px" alt="" />}
              </Box>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                backgroundColor: "transparent",
                color: "#000",
                border: "none",
                borderRadius: "20px",
                padding: "0px",
                boxShadow: "none",
              }}
              contentArrowStyle={{ display: "none" }}
              iconStyle={{
                border: "none",
                marginLeft: matches ? "0px" : "-30px",
                marginTop: matches ? "30px" : "10px",
                outline: "none",
                boxShadow: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={
                <Box
                  sx={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    border: "5px solid #ed8c24 ",
                    boxSadow: "0px 0px 12px 1px #EAB10B9C",
                    background: "#000000",
                    padding: "5px",
                  }}
                />
              }
            >
              <Box
                mt={1}
                display="flex"
                justifyContent="flex-start"
                ml={matches ? -5 : -7}
              >
                <img src={right} width={matches ? "140px" : "171px"} alt="" />
                <Box
                  mt={-2}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "700",
                    fontFamily: "Raleway",
                    color: "#fff",
                  }}
                >
                  2023 - Phase Two <br />
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Inter",
                      color: "#D9D9D9",
                    }}
                  >
                    Core Team
                  </span>
                </Box>
              </Box>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                backgroundColor: "transparent",
                color: "#000",
                border: "none",
                borderRadius: "20px",
                padding: "0px",
                boxShadow: "none",
              }}
              contentArrowStyle={{ display: "none" }}
              iconStyle={{
                border: "none",
                marginLeft: matches ? "0px" : "-30px",
                marginTop: matches ? "60px" : "60px",
                outline: "none",
                boxShadow: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={
                <Box
                  sx={{
                    mt: -12,
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    border: "5px solid #ed8c24 ",
                    boxSadow: "0px 0px 12px 1px #EAB10B9C",
                    background: "#000000",
                    padding: "5px",
                  }}
                />
              }
            >
              <Box
                mt={3}
                display="flex"
                justifyContent={matches ? "flex-start" : "flex-end"}
                mr={matches ? 0 : -7}
                ml={matches ? -4 : 0}
              >
                {matches && (
                  <img src={right} width="140px" height="40px" alt="" />
                )}
                <Box
                  mt={-2}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "700",
                    fontFamily: "Raleway",
                    color: "#fff",
                  }}
                >
                  2024 - Phase Three
                  <br />
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Inter",
                      color: "#D9D9D9",
                    }}
                  >
                    Platform Development
                  </span>
                </Box>
                {!matches && <img src={left} width="171px" alt="" />}
              </Box>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                backgroundColor: "transparent",
                color: "#000",
                border: "none",
                borderRadius: "20px",
                padding: "0px",
                boxShadow: "none",
              }}
              contentArrowStyle={{ display: "none" }}
              iconStyle={{
                border: "none",
                marginLeft: matches ? "0px" : "-30px",
                marginTop: matches ? "30px" : "10px",
                outline: "none",
                boxShadow: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={
                <Box
                  sx={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    border: "5px solid #ed8c24 ",
                    boxSadow: "0px 0px 12px 1px #EAB10B9C",
                    background: "#000000",
                    padding: "5px",
                  }}
                />
              }
            >
              <Box
                mt={1}
                display="flex"
                justifyContent="flex-start"
                ml={matches ? -5 : -7}
              >
                <img src={right} width={matches ? "140px" : "171px"} alt="" />
                <Box
                  mt={-2}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "700",
                    fontFamily: "Raleway",
                    color: "#fff",
                  }}
                >
                  2024 - Phase Four
                  <br />
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Inter",
                      color: "#D9D9D9",
                    }}
                  >
                    KYC & AUDIT done
                  </span>
                </Box>
              </Box>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                backgroundColor: "transparent",
                color: "#000",
                border: "none",
                borderRadius: "20px",
                padding: "0px",
                boxShadow: "none",
              }}
              contentArrowStyle={{ display: "none" }}
              iconStyle={{
                border: "none",
                marginLeft: matches ? "0px" : "-30px",
                marginTop: matches ? "60px" : "60px",
                outline: "none",
                boxShadow: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={
                <Box
                  sx={{
                    mt: -12,
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    border: "5px solid #ed8c24 ",
                    boxSadow: "0px 0px 12px 1px #EAB10B9C",
                    background: "#000000",
                    padding: "5px",
                  }}
                />
              }
            >
              <Box
                mt={3}
                display="flex"
                justifyContent={matches ? "flex-start" : "flex-end"}
                mr={matches ? 0 : -7}
                ml={matches ? -4 : 0}
              >
                {matches && <img src={right} width="140px" alt="" />}
                <Box
                  mt={-2}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "700",
                    fontFamily: "Raleway",
                    color: "#fff",
                  }}
                >
                  2024 - Phase Five
                  <br />
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Inter",
                      color: "#D9D9D9",
                    }}
                  >
                    Marketing launch
                  </span>
                </Box>
                {!matches && <img src={left} width="171px" alt="" />}
              </Box>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                backgroundColor: "transparent",
                color: "#000",
                border: "none",
                borderRadius: "20px",
                padding: "0px",
                boxShadow: "none",
              }}
              contentArrowStyle={{ display: "none" }}
              iconStyle={{
                border: "none",
                marginLeft: matches ? "0px" : "-30px",
                marginTop: matches ? "30px" : "10px",
                outline: "none",
                boxShadow: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={
                <Box
                  sx={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    border: "5px solid #ed8c24 ",
                    boxSadow: "0px 0px 12px 1px #EAB10B9C",
                    background: "#000000",
                    padding: "5px",
                  }}
                />
              }
            >
              <Box
                mt={1}
                display="flex"
                justifyContent="flex-start"
                ml={matches ? -5 : -7}
              >
                <img src={right} width={matches ? "140px" : "171px"} alt="" />
                <Box
                  mt={-2}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "700",
                    fontFamily: "Raleway",
                    color: "#fff",
                  }}
                >
                  2024 - Phase Six
                  <br />
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Inter",
                      color: "#D9D9D9",
                    }}
                  >
                    Staking
                  </span>
                </Box>
              </Box>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                backgroundColor: "transparent",
                color: "#000",
                border: "none",
                borderRadius: "20px",
                padding: "0px",
                boxShadow: "none",
              }}
              contentArrowStyle={{ display: "none" }}
              iconStyle={{
                border: "none",
                marginLeft: matches ? "0px" : "-30px",
                marginTop: matches ? "60px" : "60px",
                outline: "none",
                boxShadow: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={
                <Box
                  sx={{
                    mt: -12,
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    border: "5px solid #ed8c24 ",
                    boxSadow: "0px 0px 12px 1px #EAB10B9C",
                    background: "#000000",
                    padding: "5px",
                  }}
                />
              }
            >
              <Box
                mt={3}
                display="flex"
                justifyContent={matches ? "flex-start" : "flex-end"}
                mr={matches ? 0 : -7}
                ml={matches ? -4 : 0}
              >
                {matches && (
                  <img src={right} width="140px" height="40px" alt="" />
                )}
                <Box
                  mt={-2}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "700",
                    fontFamily: "Raleway",
                    color: "#fff",
                  }}
                >
                  2024 - Phase Seven
                  <br />
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Inter",
                      color: "#D9D9D9",
                    }}
                  >
                    Staking Expansion
                    <br /> (adding BNB and USDC)
                  </span>
                </Box>
                {!matches && (
                  <img src={left} width="171px" height="40px" alt="" />
                )}
              </Box>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                backgroundColor: "transparent",
                color: "#000",
                border: "none",
                borderRadius: "20px",
                padding: "0px",
                boxShadow: "none",
              }}
              contentArrowStyle={{ display: "none" }}
              iconStyle={{
                border: "none",
                marginLeft: matches ? "0px" : "-30px",
                marginTop: matches ? "30px" : "10px",
                outline: "none",
                boxShadow: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={
                <Box
                  sx={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    border: "5px solid #ed8c24 ",
                    boxSadow: "0px 0px 12px 1px #EAB10B9C",
                    background: "#000000",
                    padding: "5px",
                  }}
                />
              }
            >
              <Box
                mt={1}
                display="flex"
                justifyContent="flex-start"
                ml={matches ? -5 : -7}
              >
                <img src={right} width={matches ? "140px" : "171px"} alt="" />
                <Box
                  mt={-2}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "700",
                    fontFamily: "Raleway",
                    color: "#fff",
                  }}
                >
                  2024 - Phase Eight
                  <br />
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Inter",
                      color: "#D9D9D9",
                    }}
                  >
                    Release MANE NFT
                  </span>
                </Box>
              </Box>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                backgroundColor: "transparent",
                color: "#000",
                border: "none",
                borderRadius: "20px",
                padding: "0px",
                boxShadow: "none",
              }}
              contentArrowStyle={{ display: "none" }}
              iconStyle={{
                border: "none",
                marginLeft: matches ? "0px" : "-30px",
                marginTop: matches ? "60px" : "60px",
                outline: "none",
                boxShadow: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={
                <Box
                  sx={{
                    mt: -12,
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    border: "5px solid #ed8c24 ",
                    boxSadow: "0px 0px 12px 1px #EAB10B9C",
                    background: "#000000",
                    padding: "5px",
                  }}
                />
              }
            >
              <Box
                mt={3}
                display="flex"
                justifyContent={matches ? "flex-start" : "flex-end"}
                mr={matches ? 0 : -7}
                ml={matches ? -4 : 0}
              >
                {matches && <img src={right} width="140px" alt="" />}
                <Box
                  mt={-2}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "700",
                    fontFamily: "Raleway",
                    color: "#fff",
                  }}
                >
                  2024 - Phase Nine
                  <br />
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Inter",
                      color: "#D9D9D9",
                    }}
                  >
                    Lion's Den Gaming
                  </span>
                </Box>
                {!matches && <img src={left} width="171px" alt="" />}
              </Box>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                backgroundColor: "transparent",
                color: "#000",
                border: "none",
                borderRadius: "20px",
                padding: "0px",
                boxShadow: "none",
              }}
              contentArrowStyle={{ display: "none" }}
              iconStyle={{
                border: "none",
                marginLeft: matches ? "0px" : "-30px",
                marginTop: matches ? "30px" : "10px",
                outline: "none",
                boxShadow: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={
                <Box
                  sx={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    border: "5px solid #ed8c24 ",
                    boxSadow: "0px 0px 12px 1px #EAB10B9C",
                    background: "#000000",
                    padding: "5px",
                  }}
                />
              }
            >
              <Box
                mt={1}
                display="flex"
                justifyContent="flex-start"
                ml={matches ? -5 : -7}
              >
                <img src={right} width={matches ? "140px" : "171px"} alt="" />
                <Box
                  mt={-2}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "700",
                    fontFamily: "Raleway",
                    color: "#fff",
                  }}
                >
                  2025 - Phase Ten
                  <br />
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Inter",
                      color: "#D9D9D9",
                    }}
                  >
                    Lion Pride Merch
                  </span>
                </Box>
              </Box>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </Box>
      </Container>
    </Box>
  );
}
