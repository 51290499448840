import React, { useState, useEffect } from "react";
import moment from "moment";
import { Box } from "@mui/material";

export default function TimerCountDown({ time }) {
  const [countTime, setCountDateTime] = useState({
    time_days: 0,
    time_Hours: 0,
    time_Minusts: 0,
    // time_seconds: 0,
  });

  const startTime = async () => {
    let until = moment.unix(time).format("x");
    let interval = setInterval(() => {
      let now = moment().format("x");
      const distance = +until - +now;

      // console.log(until, "until", now, "now", distance, "distance");

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minuts = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setCountDateTime({
          ...countTime,
          time_days: days,
          time_Hours: hours,
          time_Minusts: minuts,
          time_seconds: seconds,
        });
      }
    }, 1000);
  };
  useEffect(() => {
    startTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return (
    <>
      <Box
        color="#ffffff"
        display="flex"
        alignItems="center"
        // justifyContent="center"
      >
        <Box mr={1} display="flex" alignItems="center">
          <Box borderRadius="20px" mr={0.3} textAlign="center">
            {countTime.time_days}
          </Box>
          <Box borderRadius="20px" textAlign="center" display="flex">
            D
          </Box>
        </Box>

        <Box mr={1} display="flex" alignItems="center">
          <Box borderRadius="20px" mr={0.3} textAlign="center" display="flex">
            {countTime.time_Hours}
          </Box>
          <Box borderRadius="20px" textAlign="center" display="flex">
            H
          </Box>
        </Box>

        <Box mr={1} display="flex" alignItems="center">
          <Box borderRadius="20px" mr={0.3} textAlign="center" display="flex">
            {countTime.time_Minusts}
          </Box>
          <Box borderRadius="20px" textAlign="center" display="flex">
            M
          </Box>
        </Box>
        {/* <Box mr={1} display="flex" alignItems="center">
          <Box
            borderRadius="20px"
            py={1}
            mr={0.3}
            fontSize="12px"
            textAlign="center"
            display="flex"
          >
            {countTime.time_seconds}
          </Box>
          <Box
            borderRadius="20px"
            py={1}
            fontSize="12px"
            textAlign="center"
            display="flex"
          >
            s
          </Box>
        </Box> */}
      </Box>
    </>
  );
}
