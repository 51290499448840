import {
  Box,
  Button,
  Container,
  Grid,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import battleCardbg from "../../../Images/battleCardbg.png";

import pool from "../../../Images/pool.svg";
import stakeCoin from "../../../Images/stakeCoin.svg";
import prize from "../../../Images/prize.svg";
import time from "../../../Images/time.svg";
import { formatUnits } from "viem";
import {
  stakeReadFunction,
  stakeWriteFunction,
} from "../../../ConnectivityAssets/hooks";
import Timer from "./Timer";
import { PulseLoader } from "react-spinners";
import { AppContext } from "../../../utils";
import {
  ToastNotify,
  TyposBox,
  TyposBoxsmall,
} from "../../../components/SmallComponents/AppComponents";
import Loading from "../../../components/SmallComponents/loading";

const battles = [
  {
    battleWeek: "Battle Week 8",
    packName: "Defi Sniperz",
    price: "$2,166.4",
  },
  {
    battleWeek: "Battle Week 8",
    packName: "Defi Sniperz",
    price: "$2,166.4",
  },
  {
    battleWeek: "Battle Week 8",
    packName: "Defi Sniperz",
    price: "$2,166.4",
  },
  {
    battleWeek: "Battle Week 8",
    packName: "Defi Sniperz",
    price: "$2,166.4",
  },
];

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    padding: "16px",
  },
}));

const postsPerPage = 8;

export default function Battles() {
  const matches = useMediaQuery("(max-width:950px)");
  const { account } = useContext(AppContext);

  const [currentWeek, setCurrentWeek] = useState(0);
  const [topDeposit, setTopDeposit] = useState(0);
  const [poolPrize, setPoolPrize] = useState(0);
  const [timeLeft, setTimeLeft] = useState(1);
  const [teamInfo, setTeamInfo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [lotteryCards, setLotteryCards] = useState([]);
  const [userTeamId, setUserTeamId] = useState();
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };
  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleChangepage = (event, value) => {
    setCurrentPage(value);
  };

  const getCardData = useCallback(async () => {
    try {
      setLoading(true);
      const currentWeek = await stakeReadFunction("currentWeek");
      const teamIdValue = await stakeReadFunction("getUserInfo", [account]);
      let passedWeek = 0;
      if (Number(currentWeek.toString()) > 0) {
        passedWeek = Number(currentWeek.toString()) - 1;
      } else {
        passedWeek = Number(currentWeek.toString());
      }
      const getUserWeeklyDeposit = await stakeReadFunction(
        "getUserWeeklyDepositAmount",
        [account, passedWeek]
      );
      const getTeamWeeklyDeposit = await stakeReadFunction(
        "getTeamWeeklyDepositAmount",
        [teamIdValue[5].toString(), passedWeek]
      );

      let arr = [];
      // Number(currentWeek.toString())
      for (let i = 0; i < Number(currentWeek.toString()); i++) {
        const winningTeamId = await stakeReadFunction("getWinnersHistory", [i]);
        const teamName = await stakeReadFunction("getTeamInfo", [
          winningTeamId.toString(),
        ]);
        const teamWinningAmount = await stakeReadFunction(
          "getTeamLotteryAmount",
          [winningTeamId.toString(), i]
        );

        let userClaimableAmount = 0;
        let userClaimedStatus = true;
        if (teamIdValue[5].toString() === winningTeamId.toString()) {
          userClaimedStatus = await stakeReadFunction(
            "getUserLotteryClaimedStatus",
            [account, i]
          );

          const userStakePercent =
            (+formatUnits(getUserWeeklyDeposit.toString()) /
              +formatUnits(getTeamWeeklyDeposit.toString())) *
            100;
          userClaimableAmount =
            (userStakePercent * +formatUnits(teamWinningAmount.toString())) /
            100;
        }

        const obj = {
          teamName: teamName[1],
          winningTeamId: winningTeamId.toString(),
          teamWinningAmount: +formatUnits(teamWinningAmount).toString(),
          userClaimableAmount,
          userClaimedStatus,
          getUserWeeklyDeposit: +formatUnits(getUserWeeklyDeposit.toString()),
          weekNo: i,
        };
        arr.push(obj);
        // console.log(obj, "obj");
      }
      setLotteryCards([...arr].reverse());
      console.log([...arr].reverse(), "[...arr]");
      console.log(lotteryCards.length, "length");
      setUserTeamId(teamIdValue[5].toString());
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      const errorMessage = error?.shortMessage;
      showAlert(errorMessage);
    }
  }, [account, lotteryCards.length]);

  useEffect(() => {
    if (account) {
      getCardData();
    }
  }, [account, getCardData]);

  //////////////////////////////////////////
  const init = useCallback(async () => {
    try {
      const currentWeek = await stakeReadFunction("currentWeek");
      setCurrentWeek(Number(currentWeek.toString()));

      const calculateWeek = await stakeReadFunction("calculateWeek");

      const topDeposit = await stakeReadFunction("topDepositThisWeek");
      const teams = await stakeReadFunction("uniqueTeamId");
      const poolPrize = await stakeReadFunction("lotteryPool");
      console.log(+formatUnits(poolPrize.toString(), 18), "poolPrize");
      const launchTime = await stakeReadFunction("launchTime");

      const time =
        (Number(calculateWeek.toString()) + 1) * 420 +
        Number(launchTime.toString());

      let data1 = [];
      for (let i = 1; i <= Number(teams.toString()); i++) {
        const amount = await stakeReadFunction("getTeamWeeklyDepositAmount", [
          i,
          currentWeek.toString(),
        ]);

        if (Number(formatUnits(amount.toString())) > 0) {
          const userTeam = await stakeReadFunction("getTeamInfo", [i]);
          const _teamAmount = +formatUnits(userTeam[3].toString(), 18);
          const _teamCount = userTeam[2].toString();
          const _teamName = userTeam[1];
          const _Lead = userTeam[0].toString();
          const _currentPercent = userTeam[4].toString();

          const obj = {
            lead: _Lead,
            percenet: _currentPercent,
            amount: _teamAmount,
            count: _teamCount,
            name: _teamName,
            weekDeposit: +formatUnits(amount.toString()),
          };

          // console.log("push hoa", i);
          data1.push(obj);
        }
      }

      data1 = data1.sort((a, b) => {
        return b.weekDeposit - a.weekDeposit;
      });

      data1 = data1.map((obj, i) => {
        return { ...obj, rank: i };
      });

      setTeamInfo([...data1]);
      // console.log([...data1], "[...data1]");
      setTimeLeft(time);
      setTopDeposit(+formatUnits(topDeposit.toString()));
      setPoolPrize(+formatUnits(poolPrize.toString(), 18));
    } catch (error) {
      console.error(error);
      setLoading(false);
      const errorMessage = error?.shortMessage;
      showAlert(errorMessage);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const cardsData = [
    {
      img: pool,
      title: "Prize Pool",
      amount: `$${toLocalFormat(parseFloat(poolPrize).toFixed(2))}`,
    },
    {
      img: prize,
      title: "Prize ",
      amount: `$${toLocalFormat(
        parseFloat((poolPrize * 30) / 100).toFixed(2)
      )}`,
    },
    {
      img: stakeCoin,
      title: "Total Week Stake",
      amount: `$ ${toLocalFormat(parseFloat(topDeposit).toFixed(2))}`,
    },
    {
      img: time,
      title: "Time left",
      amount: <Timer time={timeLeft} />,
    },
  ];

  const claimLottery = useCallback(async () => {
    try {
      setLoading(true);
      if (!account) {
        setLoading(false);
        return showAlert("Please connect your wallet!");
      }

      await stakeWriteFunction("claimLottery");
      setLoading(false);
      setAlertState({
        open: true,
        message: `Prize claimed successfully!`,
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      const errorMessage = error?.shortMessage;
      showAlert(errorMessage);
    }
  }, [account]);

  const pageCount = Math.ceil(teamInfo.length / postsPerPage);

  return (
    <>
      <Loading loading={loading} setLoading={setLoading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />

      <Box>
        <Container>
          {/* /////////////////////////Battle Week card/////////////////////////////////// */}

          <Box
            mb={5}
            sx={{
              background: "#272626",
              borderRadius: "24px",
              width: "100%",
              height: "100%",
              pb: 5,
            }}
          >
            <Typography
              pt={matches ? 3 : 4}
              pl={matches ? 3 : 4}
              sx={{
                fontSize: "24px",
                fontWeight: "700",
                fontFamily: "Raleway",
                color: "#fff",
              }}
            >
              Battle Week {currentWeek}
            </Typography>
            <Grid container spacing={3} mt={1} px={matches ? 3 : 4}>
              {cardsData.map(({ img, title, amount }, index) => {
                return (
                  <Grid item xs={12} md={3}>
                    <Box
                      sx={{
                        height: "80px",
                        background:
                          "linear-gradient(92.56deg, #EAB10B -12.83%, rgba(255, 255, 255, 0.06) 92.04%, rgba(255, 255, 255, 0.15) 105.06%),linear-gradient(0deg, #161718, #161718)",
                        p: "1px",
                        borderRadius: "10px",
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                          height: "100%",
                          background: "#161718",
                          borderRadius: "10px",
                        }}
                      >
                        <img
                          src={img}
                          width={"32px"}
                          height={"32px"}
                          style={{ marginLeft: "20px" }}
                          alt=""
                        />
                        <Box ml={2}>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "#D9D9D9",
                              fontFamily: "Inter",
                            }}
                          >
                            {title}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: index === 3 ? "14px" : "23px",
                              fontWeight: "600",
                              color: "#fff",
                              fontFamily: "Raleway",
                            }}
                          >
                            {amount}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          {/* /////////////////////////////////////////////////// */}

          <Box
            mb={5}
            sx={{
              background: "#272626",
              borderRadius: "24px",
              width: "100%",
              height: "100%",
              pb: 5,
            }}
          >
            <Typography
              pt={matches ? 3 : 4}
              pl={matches ? 3 : 4}
              sx={{
                fontSize: "24px",
                fontWeight: "700",
                fontFamily: "Raleway",
                color: "#fff",
              }}
            >
              Battles Legacy
            </Typography>
            {lotteryCards.length > 0 ? (
              <Box
                px={matches ? 3 : 4}
                className="lotteryScroll"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  py: 2,
                }}
              >
                {lotteryCards.map(
                  (
                    {
                      teamName,
                      teamWinningAmount,
                      userClaimableAmount,
                      winningTeamId,
                      userClaimedStatus,
                      weekNo,
                      getUserWeeklyDeposit,
                    },
                    i
                  ) => {
                    return (
                      <Box
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        key={i}
                        sx={{
                          backgroundImage: `url(${battleCardbg})`,
                          backgroundSize: "100% 100%",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "start",
                          backgroundColor: "transparent",
                          height: "194px",
                          minWidth: "310px",
                          width: "310px",
                        }}
                      >
                        <Box px={4}>
                          <Typography
                            sx={{
                              fontSize: "17px",
                              fontWeight: "600",
                              color: "#EAB10B",
                              fontFamily: "Inter",
                            }}
                          >
                            Battle Week {weekNo + 1}
                          </Typography>

                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            mt={1}
                          >
                            <TyposBox
                              text1="Lion Pride"
                              text2={teamName.replace(/www.|.com|https/gi, "")}
                            />
                            {weekNo === currentWeek - 1 &&
                              userTeamId === winningTeamId &&
                              getUserWeeklyDeposit > 0 &&
                              !userClaimedStatus && (
                                <Box
                                  sx={{
                                    width: "50%",
                                    p: 1,
                                    height: "60px",
                                    borderRadius: "10px",
                                    background: "rgba(0,0,0,0.7)",
                                  }}
                                >
                                  <TyposBoxsmall
                                    text1="Claimable Prize"
                                    text2={`$${toLocalFormat(
                                      parseFloat(userClaimableAmount).toFixed(2)
                                    )}`}
                                  />
                                </Box>
                              )}
                          </Stack>
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                            mt={1.5}
                          >
                            <TyposBox
                              text1="Won Prize"
                              text2={`$${toLocalFormat(
                                parseFloat(teamWinningAmount).toFixed(2)
                              )}`}
                            />
                            {weekNo === currentWeek - 1 &&
                              userTeamId === winningTeamId &&
                              getUserWeeklyDeposit > 0 &&
                              !userClaimedStatus && (
                                <Button
                                  onClick={claimLottery}
                                  sx={{
                                    background: "#EAB10B",
                                    boxShadow:
                                      "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    borderRadius: "10px",
                                    color: "#fff",
                                    width: "85px",
                                    height: "38px",
                                    fontSize: "12px",
                                    "&:hover": {
                                      background: "#EAB10Ba1",
                                    },
                                  }}
                                >
                                  Claim
                                </Button>
                              )}
                          </Box>
                        </Box>
                      </Box>
                    );
                  }
                )}
                {/* /////////////////////////////////////////demo cards////////////////////////////// */}
                {/* {battles?.map(({ price, packName, battleWeek }, i) => {
    return (
      <Box
        display="flex"
        alignItems="center"
        key={i}
        sx={{
          backgroundImage: `url(${battleCardbg})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "start",
          backgroundColor: "transparent",
          height: "194px",
          minWidth: "310px",
          width: "100%",
        }}
      >
        <Box pl={4}>
          <Typography
            sx={{
              fontSize: "17px",
              fontWeight: "600",
              color: "#EAB10B",
              fontFamily: "Inter",
            }}
          >
            {battleWeek}
          </Typography>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
          >
            <TyposBox text1="LionPack" text2={packName} />
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={1.5}
          >
            <TyposBox text1="Won Prize" text2={price} />
          </Stack>
        </Box>
      </Box>
    );
  })} */}
              </Box>
            ) : (
              <Typography
                py={10}
                sx={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: "400",
                  fontFamily: "Raleway",
                  color: "#fff",
                }}
              >
                you have no history yet!
              </Typography>
            )}
          </Box>

          {/* /////////////////////////Week Ranking Table/////////////////////////////////// */}

          {/* <Box
          mb={5}
          sx={{
            background: "#272626",
            borderRadius: "24px",
            width: "100%",
            height: "100%",
            pb: 5,
          }}
        >
          <Typography
            pt={matches ? 3 : 4}
            pl={matches ? 3 : 4}
            sx={{
              fontSize: "24px",
              fontWeight: "700",
              fontFamily: "Raleway",
              color: "#fff",
            }}
          >
            Week Ranking
          </Typography>
          <Box px={matches ? 3 : 4} mt={3}>
            <Box
              sx={{
                background:
                  "linear-gradient(352.62deg, #EAB10B 8.85%, rgba(255, 255, 255, 0.06) 62.12%, rgba(255, 255, 255, 0.15) 68.74%),linear-gradient(0deg, #202225, #202225)",
                p: "1px",
                borderRadius: "8px",
                height: "100%",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "none",
                  borderRadius: "8px",
                  background: "#202225",
                }}
              >
                <Table sx={{ minWidth: 600 }}>
                  <TableHead
                    sx={{
                      background:
                        "linear-gradient(180.7deg, #EAB10B 41.33%, #F33E5C 147.05%)",
                    }}
                  >
                    <TableRow
                      sx={{
                        borderBottom: "2px solid #1F1F1F",
                      }}
                    >
                      <TableCell align="center">
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#FFF",
                            fontFamily: "Inter",
                            py: 1,
                          }}
                        >
                          Rank
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#FFF",
                            fontFamily: "Inter",
                            py: 1,
                          }}
                        >
                          Lion Pride Pack Name
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#FFF",
                            fontFamily: "Inter",
                            py: 1,
                          }}
                        >
                          Members
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#FFF",
                            fontFamily: "Inter",
                            py: 1,
                          }}
                        >
                          Week Stake
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {teamInfo.length > 0 ? (
                    <TableBody>
                      {teamInfo
                        .slice(
                          currentPage * postsPerPage - postsPerPage,
                          currentPage * postsPerPage
                        )
                        .map(({ count, name, rank, weekDeposit }, i) => (
                          <TableRow
                            key={i}
                            sx={{
                              borderBottom: "2px solid #1F1F1F",
                            }}
                          >
                            <StyledTableCell>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#D9D9D9",
                                  fontFamily: "Inter",
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                {rank + 1}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#D9D9D9",
                                  fontFamily: "Inter",
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                {name.replace(/www.|.com|https/gi, "")}
                              </Typography>
                            </StyledTableCell>

                            <StyledTableCell>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#D9D9D9",
                                  fontFamily: "Inter",
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                {count}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#D9D9D9",
                                  fontFamily: "Inter",
                                  textAlign: "center",
                                  py: 1,
                                }}
                              >
                                {toLocalFormat(
                                  parseFloat(weekDeposit).toFixed(2)
                                )}
                              </Typography>
                            </StyledTableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={4}
                          style={{
                            border: "none",
                          }}
                        >
                          <Box mt={3} display="flex" justifyContent="center">
                            <PulseLoader color="#fff" size={25} />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box> */}
        </Container>
        {/* {teamInfo.length > 0 && (
        <Box my="20px">
          <Stack direction={"row"} alignItems="center" justifyContent="center">
            <Pagination
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "#fff !important",
                  "&.Mui-selected": {
                    backgroundColor: "#EAB10B !important",
                    color: "#ffffff !important",
                  },
                },
              }}
              count={pageCount}
              page={currentPage}
              onChange={handleChangepage}
            />
          </Stack>
        </Box>
      )} */}
      </Box>
    </>
  );
}
