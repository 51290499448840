import { useContext, useState, useEffect } from "react";
import Header from "./components/Header";
import HeroSection from "./pages/HeroSection";
import { useNetwork } from "wagmi";
import { AppContext } from "./utils";
import Started from "./pages/Started";
import { Box, useMediaQuery } from "@mui/material";
import sectionbg from "./Images/sectionbg.png";
import mbsectionbg from "./Images/mbsectionbg.png";
import Activity from "./pages/Activity";
import Unleash from "./pages/Unleash";
import Partners from "./pages/Partners";
import Roadmap from "./pages/Roadmap";
import Faqs from "./pages/Faqs";
import Newsletter from "./pages/Newsletter";
import { Route, Routes } from "react-router-dom";
import Admin from "./pages/DashboardPages/Admin";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  const matches = useMediaQuery("(max-width:950px)");
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Box
                sx={{
                  backgroundImage: `url(${matches ? mbsectionbg : sectionbg})`,
                  backgroundSize: { xs: "100% 100%", md: "100% 83%" },
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "start",
                  backgroundColor: "transparent",
                  height: "100%",
                }}
              >
                <Header />
                <HeroSection />
              </Box>
              <Started />
              <Activity />
              <Unleash />
              <Partners />
              <Roadmap />
              <Faqs />
              <Newsletter />
            </>
          }
        />
        <Route path="/dapp/*" element={<Admin />} />
      </Routes>
    </>
  );
}

export default App;
