import React from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import card from "../Images/card.png";
import fund from "../Images/fund.png";
import trade from "../Images/trade.png";
import bank from "../Images/bank.png";

const cardData = [
  {
    img: fund,
    title: "STAKING",
    coming: "",
    describe:
      "Earn up to 3% daily and an APR of up to 658% at Lion Capital, that's all thanks to the external revenue streams that facilitate these returns. By staking your USDT today, you can tap into the exciting world of DeFi and start earning right away.",
  },
  {
    img: trade,
    title: "Lion Pride",
    coming: "Coming Soon",
    describe:
      "This is the era of teamwork, of shared success. By working together, we can do more, which is why we foster a supportive environment in which everybody can flourish. When you Join a Lion Pride, you're not simply investing – you're becoming a part of a closely-knit community who are dedicated to helping you and every other member succeed. Together, we'll harness the power of unity to make staking more profitable and fun for all involved.    ",
  },
  {
    img: bank,
    title: "Team Lottery",
    coming: "Coming Soon",
    describe:
      "Collaborate with your Lion Pride teammates and unlock the benefits of collective effort. At Lion Capital, the Lion Pride with the highest combined deposits each week wins the weekly challenge. This fosters teamwork and camaraderie while adding an extra thrill to your staking journey.",
  },
];
export default function Started() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <>
      <Box pt={matches ? 5 : 10} pb={matches ? 10 : 20}>
        <Container maxWidth="lg">
          <Typography
            sx={{
              fontSize: { xs: "40px", md: "53px" },
              fontWeight: "800",
              fontFamily: "Raleway",
              color: "#fff",
              lineHeight: { xs: "40px", md: "55px" },
              textAlign: "center",
            }}
          >
            Get Started Today
          </Typography>
          <Typography
            mt={2}
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              fontFamily: "Inter",
              color: "#D9D9D9",
              textAlign: "center",
            }}
          >
            Discover exciting opportunities for financial growth with Lion
            Capital's range of innovative products and services.
          </Typography>

          <Grid container spacing={5} mt={4}>
            {cardData.map(({ img, title, coming, describe }, index) => {
              return (
                <Grid item xs={12} md={4}>
                  <div
                    duration={5000}
                    data-aos={
                      index === 0
                        ? "fade-right"
                        : index === 1
                        ? "fade-up"
                        : "fade-left"
                    }
                  >
                    <Box
                      sx={{
                        backgroundImage: `url(${card})`,
                        backgroundSize: "100% 100%",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundColor: "transparent",
                        height: "420px",
                        width: "100%",
                        boxShadow: "rgba(234, 177, 11, 0.15) 0px -20px 120px",
                        borderRadius: "35px",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Box pt={3}>
                        <img src={img} width="65px" height="65px" alt="" />
                      </Box>
                      <Typography
                        pt={2}
                        sx={{
                          fontSize: "22px",
                          fontWeight: "800",
                          fontFamily: "Raleway",
                          color: "#fff",
                          textAlign: "center",
                        }}
                      >
                        {title}
                      </Typography>
                      {/* <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "500",
                          fontFamily: "Raleway",
                          color: "#EAB10B",
                          textAlign: "center",
                        }}
                      >
                        {coming}
                      </Typography> */}
                      <Typography
                        // pt={(index === 1) | (index === 2) ? 2 : 5}
                        pt={1}
                        px={3}
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "Inter",
                          color: "#D9D9D9",
                          textAlign: "center",
                        }}
                      >
                        {describe}
                      </Typography>
                    </Box>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>
    </>
  );
}
