import * as React from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  Link as MuiLink,
} from "@mui/material";

// All Icones used in this component
import { MdExpandMore } from "react-icons/md";
import MenuIcon from "@mui/icons-material/Menu";
import dashboard from "../../Images/dashboard.svg";
import stake from "../../Images/stake.svg";
import shar from "../../Images/shar.png";
import battle from "../../Images/battle.svg";
import more from "../../Images/more.svg";
//components from files
import { AppContext } from "../../utils";
import logo from "../../Images/logo.png";
import { ExampleButton } from "../../components/SmallComponents/StyledWalletButton";

//Style box for social media links
// const StyleSocialBox = ({ miniDrawerShow, array, ...props }) => {
//   const loc = useLocation();
//   return (
//     <Stack
//       {...props}
//       direction="column"
//       ml={miniDrawerShow ? 0 : 1}
//       gap={2}
//       justifyContent="center"
//     >
//       {array.map(({ link, Icon, name, active, img: logo }, i) => {
//         return (
//           <React.Fragment key={i}>
//             {active ? (
//               <Link
//                 key={i}
//                 to={link}
//                 style={{
//                   display: !miniDrawerShow && "flex",
//                   gap: !miniDrawerShow && 20,
//                   alignItems: !miniDrawerShow && "center",
//                   textDecoration: "none",
//                 }}
//               >
//                 {loc.pathname === link ? (
//                   <img
//                     src={active}
//                     alt="logo"
//                     style={{
//                       width: "32px",
//                       height: "32px",
//                     }}
//                   />
//                 ) : (
//                   <img
//                     src={logo}
//                     alt="logo"
//                     style={{
//                       width: "32px",
//                       height: "32px",
//                     }}
//                   />
//                 )}
//                 {!miniDrawerShow && (
//                   <Typography
//                     component="span"
//                     variant="subtitle1"
//                     sx={{
//                       fontSize: { xs: "15px", md: "16px" },
//                       color: loc.pathname === link ? "#0C82D8" : "#fff",
//                     }}
//                   >
//                     {name}
//                   </Typography>
//                 )}
//               </Link>
//             ) : (
//               <MuiLink
//                 href={link}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 sx={{
//                   display: !miniDrawerShow && "flex",
//                   gap: !miniDrawerShow && 2.5,
//                   alignItems: !miniDrawerShow && "center",
//                   textDecoration: "none",
//                 }}
//               >
//                 <Icon style={{ fontSize: "2rem", color: "#fff" }} />
//                 {!miniDrawerShow && (
//                   <Typography
//                     component="span"
//                     variant="subtitle1"
//                     sx={{ fontSize: { xs: "15px", md: "16px" } }}
//                   >
//                     {name}
//                   </Typography>
//                 )}
//               </MuiLink>
//             )}
//           </React.Fragment>
//         );
//       })}
//     </Stack>
//   );
// };

//array of pages names and links
const pagesName = [
  {
    link: "/dapp",
    text: "Dashboard",
    img: dashboard,
  },
  {
    link: "/dapp/stake",
    text: "Staking",
    img: stake,
  },
  {
    link: "/dapp/packs",
    text: "Lion Pride",
    img: shar,
  },
  {
    link: "/dapp/weekly-battles",
    text: "Weekly Battles",
    img: battle,
  },
];

//array of social media links and icons
// const socialIcons1 = [
//   {
//     link: "https://t.me/WolfCapitalUSDT",
//     Icon: TelegramIcon,
//     name: "Telegram",
//   },
//   {
//     link: "https://twitter.com/Wolf_Capital_",
//     Icon: TwitterIcon,
//     name: "Twitter",
//   },
//   {
//     link: "https://discord.gg/Pgj3XqFy4f",
//     Icon: SiDiscord,
//     name: "Discord",
//   },
//   {
//     link: "https://docs.wolfCapital.app/",
//     Icon: SiGitbook,
//     name: "Gitbook",
//   },
//   {
//     link: "/dapp/nft-addresses",
//     Icon: FaFileContract,
//     name: "Smart Contract",
//     img: contracticon,
//     active: contractActive,
//   },
// ];

// array of appBar boxes

// ---------------------------------------

function ResponsiveDrawer(props) {
  const { window, children, miniDrawerShow } = props;
  // const [socialIcon, setSocialIcon] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const check = useMediaQuery("(max-width:700px)");
  // const check4 = useMediaQuery("(max-width:1200)");
  const drawerWidth = miniDrawerShow ? 120 : "292px";
  // const minidrawerWidth = 120;

  const loc = useLocation();

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    // console.log("toggle");
  };

  const drawer = (
    <Box
      sx={{
        position: "relative",
        paddingBottom: "50px",
        height: "100%",
      }}
    >
      {/* <Container> */}
      <NavLink to="/" style={{ textDecoration: "none" }}>
        <Box mt={3} mb={6} display="flex" justifyContent="center">
          <img src={logo} width="170px" alt="" />
        </Box>
      </NavLink>

      {/* pages integration with respect to links */}

      <List>
        {pagesName.map(({ link, text, img }, i) => {
          return (
            <React.Fragment key={i}>
              <NavLink key={i} to={link} style={{ textDecoration: "none" }}>
                <ListItem
                  sx={{
                    background: loc.pathname === link && "#181818",
                    borderRadius: loc.pathname === link && "0px",
                    borderRight: loc.pathname === link && "2px solid #EC971D",
                    mb: 3.5,
                    paddingX: "45px",
                    paddingY: "10px",
                    "&:hover": {
                      backgroundColor:
                        loc.pathname === link ? "#181818" : "transparent",
                    },
                  }}
                  onClick={() => check && handleDrawerToggle()}
                >
                  <>
                    <img
                      src={img}
                      alt="logo"
                      style={{
                        width: "27px",
                        height: "27px",
                      }}
                    />
                  </>

                  <ListItemText sx={{ paddingLeft: "9%" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        fontFamily: "Inter",
                        color: "#fff",
                      }}
                    >
                      {text}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </NavLink>
            </React.Fragment>
          );
        })}
      </List>

      {/* social media links of miner dao */}

      {/* <Box
        // onClick={() => setSocialIcon(!socialIcon)}
        display="flex"
        alignItems="center"
        sx={{
          paddingX: "45px",
          cursor: "pointer",
        }}
      >
        <img src={more} alt="" width="27px" height="27px" />
        <Typography
          sx={{
            paddingLeft: "9%",
            fontSize: "16px",
            fontWeight: "500",
            fontFamily: "Inter",
            color: "#fff",
          }}
        >
          More
        </Typography>
        <MdExpandMore
          style={{
            color: "#fff",
            fontSize: "24px",
            marginLeft: "20px",
          }}
        />
      </Box> */}
      {/* {socialIcon && (
          <Box
            sx={{
              borderLeft: "2px solid #D9D9D9",
              px: "13px",
              ml: "25px",
              mt: "26px",
            }}
          >
            <StyleSocialBox array={socialIcons1} />
          </Box>
        )} */}

      <Toolbar />
      {/* </Container> */}
    </Box>
  );

  return (
    <>
      <Box className="side_drawer">
        <Box sx={{ display: "flex" }}>
          {/* ------- menu and arrow ------- */}
          <AppBar
            position="absolute"
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
              zIndex: 1,
              display: {
                xs: "flex",
                lg: "none",
              },
            }}
          >
            <Box p={2}>
              <Box
                sx={{
                  display: { xs: "flex", lg: "none" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{
                    ml: 0,
                    color: "#fff",
                    background: "transparent",
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <ExampleButton />
              </Box>
            </Box>
          </AppBar>

          {/* //////////////////////// */}
          <Box
            component="nav"
            sx={{
              width: { lg: drawerWidth },
              flexShrink: { sm: 0 },
              transition: "0.07s ease-in-out",
            }}
            aria-label="mailbox folders"
            zIndex={0}
          >
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: "block",
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: 280,
                  background: "#272626",
                  borderRadius: "0px 39px 39px 0px",
                },
                height: "100%",
              }}
            >
              {drawer}
            </Drawer>
            {/* desktop view drawer */}

            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", lg: "block" },
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  transition: "0.07s ease-in-out",
                  boxSizing: "border-box",
                  background: "#272626",
                  borderRadius: "0px 39px 39px 0px",
                },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              width: {
                xs: "100%",
                sm: `calc(100% - 292px)`,
              },
              minHeight: "100vh",
              height: "100%",
              transition: "0.07s ease-in-out",
            }}
          >
            <Toolbar />
            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
}
ResponsiveDrawer.propTypes = { window: PropTypes.func };
export default ResponsiveDrawer;
