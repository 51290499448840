import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { FaBell } from "react-icons/fa";
import { IoMdSearch } from "react-icons/io";
import { ExampleButton } from "../../components/SmallComponents/StyledWalletButton";
import user from "../../Images/user.png";
export default function DashHeader() {
  return (
    <>
      <Container maxWidth="xl">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "600",
                color: "#fff",
                fontFamily: "Raleway",
              }}
            >
              <span style={{ color: "#EAB10B" }}> welcome back</span>
            </Typography>
            <Typography
              pt={1}
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#fff",
                fontFamily: "Inter",
              }}
            >
              Roaring into the Future of Staking.
            </Typography>
          </Box>
          <ExampleButton />
          {/* <Box
            mx={2}
            sx={{
              background: "transparent",
              width: "40px",
              height: "40px",
              borderRadius: "12px",
              border: "1px solid #EAB10B",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <FaBell style={{ fontSize: "20px", color: "#fff" }} />
          </Box>
          <Box
            mr={2}
            sx={{
              background: "transparent",
              width: "40px",
              height: "40px",
              borderRadius: "12px",
              border: "1px solid #EAB10B",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <IoMdSearch style={{ fontSize: "20px", color: "#fff" }} />
          </Box>
          <img src={user} width="40px" height="40px" alt="" />
          <Typography
            pl={2}
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#fff",
              fontFamily: "Inter",
            }}
          >
            Matthew Johnson
          </Typography> */}
        </Box>
      </Container>
    </>
  );
}
